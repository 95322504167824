import React from 'react';
import { PropTypes } from 'prop-types';

const ContentTabs = ({ children }) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <div className="tabs-container">{children}</div>
      </div>
    </div>
  </div>
);

ContentTabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentTabs;

import React from 'react';
import { PropTypes } from 'prop-types';

const Content = ({ title, children }) => (
  <div className="row">
    <div className="col-lg-12">
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h5>{title}</h5>
        </div>
        <div className="ibox-content">{children}</div>
      </div>
    </div>
  </div>
);

Content.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Content;

/* eslint-disable */
import React from 'react';

export default (props) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal" onSubmit={props.handleSubmit}>
          <div className="form-group">
            <label className="col-sm-2 control-label">Nome *</label>
            <div className="col-sm-6">
              <input
                name="name"
                value={props.name}
                onChange={props.handleChange}
                type="text"
                placeholder="Nome"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Sub-Grupo *</label>
            <div className="col-sm-6">
              <select
                className="form-control"
                onChange={props.handleChange}
                name="subGroup"
                value={props.subGroup}
                required
              >
                <option>[Selecione uma opção]</option>
                {props.rows.map((response, key) => (
                  <option key={key} value={response.id}>
                    {response.name.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Permissões</label>
            <div className="col-sm-6">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>URI</th>
                    <th>LISTAR</th>
                    <th>CRIAR</th>
                    <th>EDITAR</th>
                    <th>EXCLUIR</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(props.permission).map((key) => (
                    <tr key={key}>
                      <td>{props.permission[key].uri.toUpperCase()}</td>
                      <td>
                        <input
                          type="checkbox"
                          value={[key, 'list']}
                          onChange={props.handleChangeFormPermission}
                          // {...(props.permission[key].crud[0].list === "1" ? {defaultChecked: true} : {}) }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={[key, 'create']}
                          onChange={props.handleChangeFormPermission}
                          // {...(props.permission[key].crud[0].create === "1" ? {defaultChecked: true} : {}) }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={[key, 'edit']}
                          onChange={props.handleChangeFormPermission}
                          // {...(props.permission[key].crud[0].edit === "1" ? {defaultChecked: true} : {}) }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={[key, 'delete']}
                          onChange={props.handleChangeFormPermission}
                          // {...(props.permission[key].crud[0].delete === "1" ? {defaultChecked: true} : {}) }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              <button
                className="btn btn-white"
                type="button"
                onClick={() => props.onClickReturnPage()}
              >
                Voltar
              </button>
              <button className="btn btn-primary" type="submit">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { validatorPermissionsURI } from '../../../../utils';

export default (props) => (
  <>
    {validatorPermissionsURI('salle') ? (
      <li className={props.page === 'salle' ? 'active' : ''}>
        <Link to="/salle">
          <i className="fa fa-dollar" />
          <span className="nav-label">Vendas</span>
        </Link>
      </li>
    ) : (
      ''
    )}

    {validatorPermissionsURI('salle') ? (
      <li className={props.page === 'search' ? 'active' : ''}>
        <Link to="/search">
          <i className="fa fa-search" />
          <span className="nav-label">Consultas</span>
        </Link>
      </li>
    ) : (
      ''
    )}

    {validatorPermissionsURI('attendance') ? (
      <li className={props.page === 'attendance' ? 'active' : ''}>
        <Link to="/attendance">
          <i className="fa fa-phone" />
          <span className="nav-label">Atendimentos</span>
        </Link>
      </li>
    ) : (
      ''
    )}
  </>
);

/* eslint-disable */
import axios from 'axios';

import { validateToken } from '../../../utils';

import { URL_BACKEND_OPERATION } from '../../../constants';

const HEADERS = {
  'auth-jwt': `Bearer ${validateToken('token')}`,
};

/**
 * Function Get List by Sub Group
 */
export async function getListAllSubGroup() {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/sub-group/`,
      { headers: HEADERS }
    );
    return { status: true, rows: response.data };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Get List by Sub Group
 */
export async function getListSubGroup(queryString) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/sub-group/list?${queryString}&is_active=true`,
      { headers: HEADERS }
    );
    return {
      status: true,
      rows: response.data.data,
      totalRecord: response.data.paginate.total,
    };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Insert Sub Group
 */
export async function insertSubGroup(body) {
  try {
    await axios.post(`${URL_BACKEND_OPERATION}/callcenter/sub-group/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'SubGrupo inserido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Update Sub Group
 */
export async function updateSubGroup(body) {
  try {
    await axios.put(`${URL_BACKEND_OPERATION}/callcenter/sub-group/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'SubGrupo alterado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Delete Sub Group
 */
export async function deleteSubGroup(id) {
  try {
    await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/sub-group/${id}`, {
      headers: HEADERS,
    });
    return { status: true, message: 'SubGrupo removido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

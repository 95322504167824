/* eslint-disable */
import React, { Component } from 'react';

import {
  getListProfile,
  getListUsers,
  insertUsers,
  updateUsers,
  uploadFileUsers,
  deleteUsers,
} from '../../../services';

import { toastrMessage } from '../../../utils';

import Layout from '../../../layout';
import Content from '../../../components/Content';
import Datatables from '../../../components/Datatables';
import FormUsers from '../../../components/Forms/Settings/Users';
import FormUploadUsers from '../../../components/Forms/Settings/UploadUsers';

export default class Users extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      system: 'settings',
      page: 'users',
      description: 'Configurações :: Usuários',
      title: 'Listagem dos Usuários',
      progressBar: false,
      displayList: true,
      displayForm: false,
      displayFormUpdate: false,
      displayFormUploadUsers: false,
      statusFlashMessage: '',
      flashMessage: '',
      action: '',
      name: '',
      userName: '',
      password: '',
      profileId: '',
      status: '',
      file: '',
      identify: 'callcenter',
      requiredPassword: true,
      columns: {
        id: '#',
        name: 'NOME',
        userName: 'USUÁRIO',
        profileName: 'PERFIL',
        status: 'STATUS',
      },
      usersList: [],
      totalRecord: 0,
      profileList: [],
    };

    this.baseState = this.state;
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  /**
   * Component Did Mount
   */
  async componentDidMount() {
    await this.getListUsers();
    await this.getListProfile();
  }

  /**
   * Component Will Unmount
   */
  async componentWillUnmount() {
    this.setState(this.baseState);
  }

  /**
   * On Click Button Upload File
   */
  onClickButtonUploadFile = () => {
    this.setState({
      displayList: false,
      displayFormUploadUsers: true,
      title: 'Importar Atendentes',
      action: 'upload',
    });
  };

  /**
   * On Click Return Page
   */
  onClickReturnPage = () => {
    this.setState({
      displayList: true,
      displayForm: false,
      displayFormUploadUsers: false,
      name: '',
      userName: '',
      password: '',
      profileId: '',
      file: '',
      status: '',
    });
  };

  /**
   * Function Insert Record
   */
  onClickInsertRecord = () => {
    this.setState({
      displayList: false,
      displayForm: true,
      displayFormUploadUsers: false,
      title: 'Inserir Registro',
      action: 'insert',
    });
  };

  /**
   * Function Edit Record
   * @param {object} record
   */
  onClickEditRecord = (record) => {
    this.setState({
      displayList: false,
      displayForm: true,
      userId: record.id,
      profileId: record.profileId,
      name: record.name,
      userName: record.userName,
      status: record.status,
      requiredPassword: false,
      title: 'Editar Registro',
      action: 'edit',
    });
  };

  /**
   * Function get List Users
   */
  async getListUsers(queryString = 'page=1&limit=10') {
    this.setState({ progressBar: true });

    const response = await getListUsers(queryString);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.setState({
      loader: false,
      progressBar: false,
      displayForm: false,
      displayFormUploadUsers: false,
      displayList: true,
      name: '',
      userName: '',
      password: '',
      profileId: '',
      status: '',
      file: '',
      usersList: response.rows,
      totalRecord: response.totalRecord,
    });
  }

  /**
   * Function Table Change Handler
   * @param {object} data
   */
  tableChangeHandler = (data) => {
    const queryString = Object.keys(data)
      .map((key) => {
        switch (key) {
          case 'page_number':
            return `${encodeURIComponent('page')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'page_size':
            return `${encodeURIComponent('limit')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'sort_order':
            return data[key]
              ? `${encodeURIComponent('sort')}=${encodeURIComponent(
                  data[key].order
                )}`
              : '';
          case 'filter_value':
            return data[key]
              ? `${encodeURIComponent('search')}=${encodeURIComponent(
                  data[key]
                )}`
              : '';
          default:
            return '';
        }
      })
      .filter((value) => !!value)
      .join('&');
    this.getListUsers(queryString);
  };

  /**
   * Function Get List All Profile
   */
  async getListProfile(queryString = 'page=1&limit=1000') {
    this.setState({ progressBar: true });

    const response = await getListProfile(queryString);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    const data = [];

    response.rows.forEach((row) => {
      data.push({
        id: row.id,
        name: `${row.group_name} | ${row.name}`,
      });
    });

    this.setState({
      progressBar: false,
      profileList: data,
    });
  }

  /**
   * Function Insert Users
   */
  async insertUsers() {
    const { name, userName, password, profileId } = this.state;

    this.setState({ progressBar: true });

    const response = await insertUsers({
      name,
      username: userName,
      password,
      profile: parseInt(profileId),
    });

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.getListUsers();
    toastrMessage('success', response.message);
  }

  /**
   * Function Insert Users
   */
  async updateUsers() {
    const { name, userName, password, profileId, userId, status } = this.state;

    this.setState({ progressBar: true });

    const response = await updateUsers({
      id: userId,
      name,
      username: userName,
      password,
      profile: parseInt(profileId),
      deleted_at: status === 'ATIVO' ? 0 : 1,
    });

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.getListUsers();
    toastrMessage('success', response.message);
  }

  /**
   * Function Delete Users
   */
  async delete(record) {
    if (window.confirm('Você deseja desativar esse usuário?')) {
      this.setState({ progressBar: true });

      const response = await deleteUsers(record.id);

      if (!response.status) {
        this.setState({ progressBar: false });
        toastrMessage('error', response.message);
        return;
      }

      toastrMessage('success', response.message);
      this.getListUsers();
    }
  }

  /**
   * Function Upload File Users
   */
  async uploadFileUsers() {
    const { identify, profileId, file } = this.state;

    this.setState({ progressBar: true });

    if (file.size > 1024 * 1024 * 2) {
      toastrMessage(
        'error',
        'Ops, o arquivo enviado excede o limite de 2MB permitido, reduza o arquivo ou envie outro de até 2MB!'
      );
      this.setState({ progressBar: false });
      return;
    }

    const data = new FormData();
    data.append('file', file);
    data.append('identify', identify);
    data.append('profile_id', profileId);

    const response = await uploadFileUsers(data);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.getListUsers();
    toastrMessage('success', response.message);
  }

  /**
   * Handle Change Form Upload File
   * @param {event} e
   */
  handleChangeFormUploadFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  /**
   * Handle Change Form Send Username and Password
   * @param {event} e
   */
  handleChangeForm(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Handle Submit Form Send Username and Password
   * @param {event} e
   */
  async handleSubmitForm(e) {
    e.preventDefault();

    const { action } = this.state;

    switch (action) {
      case 'edit':
        this.updateUsers();
        break;

      case 'upload':
        this.uploadFileUsers();
        break;

      default:
        this.insertUsers();
        break;
    }
  }

  /**
   * Render Component
   */
  render() {
    const {
      system,
      page,
      description,
      title,
      progressBar,
      name,
      userName,
      password,
      profileId,
      status,
      displayList,
      displayForm,
      displayFormUploadUsers,
      columns,
      requiredPassword,
      file,
      usersList,
      profileList,
      totalRecord,
    } = this.state;

    return (
      <Layout
        system={system}
        page={page}
        description={description}
        progressBar={progressBar}
      >
        <Content title={title}>
          {displayList && (
            <Datatables
              page={page}
              permissions={page}
              columns={columns}
              rows={usersList}
              loading={progressBar}
              filter
              btnAddDefault
              btnEditDefault
              btnDeleteDefault
              insertRecord={(e) => this.onClickInsertRecord(e)}
              editRecord={(e) => this.onClickEditRecord(e)}
              deleteRecord={(e) => this.delete(e)}
              onClickButtonUploadFile={(e) => this.onClickButtonUploadFile(e)}
              dynamic
              totalRecord={totalRecord}
              onChange={(e) => this.tableChangeHandler(e)}
            />
          )}
          {displayForm && (
            <FormUsers
              name={name}
              status={status}
              userName={userName}
              password={password}
              profileId={profileId}
              requiredPassword={requiredPassword}
              profileList={profileList}
              handleChange={this.handleChangeForm}
              handleSubmit={this.handleSubmitForm}
              onClickReturnPage={this.onClickReturnPage}
            />
          )}

          {displayFormUploadUsers && (
            <FormUploadUsers
              profileId={profileId}
              file={file}
              profileList={profileList}
              handleChange={this.handleChangeForm}
              handleChangeFormUploadFile={(e) =>
                this.handleChangeFormUploadFile(e)
              }
              handleSubmit={this.handleSubmitForm}
              onClickReturnPage={this.onClickReturnPage}
            />
          )}
        </Content>
      </Layout>
    );
  }
}

/* eslint-disable */
import axios from 'axios';

import { URL_BACKEND_OPERATION } from '../../constants';

const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Function Authorize FS Callcenter
 * @param {json} body
 */
export async function authorization(body) {
  try {
    const response = await axios.post(
      `${URL_BACKEND_OPERATION}/callcenter/auth/login`,
      body,
      { headers: HEADERS }
    );
    return { status: true, token: response.data.token };
  } catch (error) {
    if (error.request.status === 404) {
      return {
        status: false,
        message: `Ops, seu usuário ou senha estão incorretos. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

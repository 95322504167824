import React from 'react';
import { PropTypes } from 'prop-types';

const Content = ({ title, children }) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2 control-label">{title}</label>
            <div className="col-sm-10">{children}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

Content.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Content;

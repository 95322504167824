import React from 'react';
import { PropTypes } from 'prop-types';

const FormInsuranceSaleConfirmSale = ({
  displayFormConfirmRegister,
  fullName,
  cpf,
  phone,
  productName,
  productPrice,
  onClickButtonBack,
  onClickButtonConfirmSaleProduct,
}) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2 control-label">Confirmar Compra *</label>
            <div className="col-sm-4">
              {displayFormConfirmRegister && (
                <div>
                  <div className="alert alert-success">
                    <p>
                      <strong>Script</strong>
                    </p>
                    <p>
                      - Senhor, antes de concluir a venda do seguro será
                      necessário confirmar algumas informações:{' '}
                    </p>
                  </div>
                  <div className="hr-line-dashed" />
                  <p>
                    <strong>Confirmação dos Dados do Cliente</strong>
                  </p>
                  <p>
                    Cliente: <strong>{fullName}</strong>
                  </p>
                  <p>
                    CPF: <strong>{cpf}</strong>
                  </p>
                  <p>
                    Número da Linha Segurada Vivo Fixa::{' '}
                    <strong>{phone}</strong>
                  </p>
                  <div className="hr-line-dashed" />
                </div>
              )}

              <p>
                Produto: <strong>{productName}</strong>
              </p>
              <p>
                Valor: <strong>{productPrice}</strong>
              </p>
              <div className="hr-line-dashed" />
              <div className="alert alert-success">
                <p>
                  <strong>Script</strong>
                </p>
                <p>
                  - Senhor, para sua segurança, informo que essa ligação está
                  sendo gravada.
                </p>
                <p>
                  - O Senhor confirma seu interesse em aderir ao “{productName}
                  ”?
                </p>
              </div>
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              <button
                className="btn btn-white"
                type="button"
                onClick={onClickButtonBack}
                style={{ marginRight: 5 }}
              >
                Voltar
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={onClickButtonConfirmSaleProduct}
              >
                Confirmar Venda
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

FormInsuranceSaleConfirmSale.propTypes = {
  displayFormConfirmRegister: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string.isRequired,
  onClickButtonBack: PropTypes.func.isRequired,
  onClickButtonConfirmSaleProduct: PropTypes.func.isRequired,
};

export default FormInsuranceSaleConfirmSale;

/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import { validateToken } from '../../../utils';

import { URL_BACKEND, BRAND_NAME, REQUESTER_NAME } from '../../../constants';

const HEADERS = {
  'Content-Type': 'application/json',
  'X-FS-Brand-Name': BRAND_NAME,
  'X-FS-Requester-Name': REQUESTER_NAME,
  Authorization: `Bearer ${validateToken('token')}`,
};

/**
 * Function Subscribe Callcenter
 * @param {int} userIdentity
 * @param {int} callcenterUserId
 * @param {int} campaignId
 * @param {string} fullName
 * @param {string} birthDate
 * @param {int} phone
 * @param {int} msisdn
 * @param {int} cpf
 * @param {string} email
 * @param {string} gender
 * @param {int} rg
 * @param {int} protocol
 * @param {string} maritalStatus
 * @param {string} complement
 * @param {int} number
 * @param {int} cep
 * @param {string} address
 * @param {string} neighborhood
 * @param {string} city
 * @param {string} state
 */
export async function subscribeCallcenter(
  userIdentity,
  callcenterUserId,
  campaignId,
  fullName,
  birthDate,
  phone,
  msisdn,
  cpf,
  email,
  gender,
  rg,
  protocol,
  maritalStatus,
  complement,
  number,
  cep,
  address,
  neighborhood,
  city,
  state
) {
  if (birthDate) {
    birthDate = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  if (msisdn) {
    msisdn = `55${msisdn}`;
  }

  const body = {
    campaign_id: campaignId,
    identifier: userIdentity,
    fullname: fullName,
    birth_date: birthDate,
    phone: `55${phone}`,
    cpf,
    email,
    gender,
    rg,
    protocol,
    callcenter_userid: callcenterUserId,
    partner: 'vivo fixa',
    marital_status: maritalStatus,
    address_complement: complement,
    address_number: number,
    address: {
      cep,
      description: address,
      neighborhood: {
        description: neighborhood,
        city: {
          description: city,
          uf: {
            description: state,
            code: state,
          },
        },
      },
    },
  };

  try {
    await axios.post(
      `${URL_BACKEND}/bff/api/v2/insurance/callcenter/subscribe`,
      body,
      { headers: HEADERS }
    );
    return {
      status: true,
      message: 'Registro inserido com sucesso!',
      dateProtocol: moment().format('DD/MM/YYYY HH:mm:ss'),
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        return {
          status: 'info',
          message: `Ops, atualize os dados do cliente e tente novamente!`,
        };
      }

      if (error.response.status === 409) {
        return {
          status: 'info',
          message: `Ops, o cliente já possui o produto selecionado!`,
        };
      }

      if (error.response.status === 401) {
        return {
          status: 'info',
          message: `Ops, o cliente já possui o produto selecionado!`,
        };
      }

      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function UnSubscribe Callcenter
 * @param {int} carrierSubscriptionId
 * @param {int} insurancePolicyNumber
 * @param {int} protocol
 * @param {int} callcenterUserId
 * @param {string} partner
 */
export async function unSubscribeCallcenter(
  carrierSubscriptionId,
  insurancePolicyNumber,
  protocol,
  callcenterUserId,
  client_id,
  partner = 'vivo fixa'
) {
  const body = {
    carrier_subscription_id: carrierSubscriptionId,
    insurance_policy_number: insurancePolicyNumber,
    protocol,
    callcenter_userid: callcenterUserId,
    clientId: client_id,
    partner,
  };

  try {
    await axios.post(
      `${URL_BACKEND}/bff/api/v2/insurance/callcenter/unsubscribe`,
      body,
      { headers: HEADERS }
    );
    return { status: true, message: 'Registro inserido com sucesso!' };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Validator RG
 * @param {int} rg
 */
export function maskRG(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '$1.$2');
  value = value.replace(/(\d{3})(\d)/g, '$1.$2');
  value = value.replace(/(\d{3})(\d)/g, '$1-$2');
  return value;
}

/* eslint-disable */
import React from 'react';
import { validateToken } from '../../../utils';

import ButtonLogout from '../../Buttons/Logout';

export default (props) => (
  <>
    <div className="row border-bottom">
      <nav className="navbar navbar-static-top" role="navigation">
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <span className="m-r-sm welcome-message" style={{ color: '#fff' }}>
              Você está logado(a) como: <b>{validateToken('name')}</b>
            </span>
          </li>
          <li>
            <ButtonLogout />
          </li>
        </ul>
      </nav>
    </div>
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-10">
        <h2>{props.description}</h2>
      </div>
      <div className="col-lg-2" />
    </div>
  </>
);

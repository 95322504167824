/* eslint-disable */
import React, { Component } from 'react';
import Layout from '../../../layout';
import Content from '../../../components/Content';
import Datatables from '../../../components/Datatables';
import FormGroup from '../../../components/Forms/Settings/Group';

import {
  getListGroup,
  insertGroup,
  updateGroup,
  deleteGroup,
} from '../../../services';

import { toastrMessage } from '../../../utils/Toastr';

export default class Group extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      system: 'settings',
      page: 'group',
      description: 'Configurações :: Grupos',
      title: 'Listagem dos Grupos',
      progressBar: true,
      displayList: true,
      displayForm: false,
      displayFormUpdate: false,
      displayFormAdvanceSettings: false,
      statusFlashMessage: '',
      flashMessage: '',
      action: '',
      groupId: '',
      name: '',
      nivel: '100',
      identify: 'callcenter',
      columns: {
        id: '#',
        group_name: 'NOME',
        group_nivel: 'NIVEL',
        group_identify: 'IDENTIFICADOR',
      },
      rows: [],
      totalRecord: 0,
    };

    this.baseState = this.state;
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getData();
  }

  /**
   * On Click Button Return Page
   */
  onClickButtonReturnPage = () => {
    this.setState(this.baseState);
    this.getData();
  };

  /**
   * Function Button Insert Group
   */
  onClickButtonInsert = () => {
    this.setState({
      displayList: false,
      displayForm: true,
      title: 'Inserir Registro',
      action: 'insert',
    });
  };

  /**
   * Function Button Edit Group
   * @param {object} group
   */
  onClickButtonEdit = (group) => {
    this.setState({
      displayList: false,
      displayForm: true,
      groupId: group.id,
      name: group.group_name,
      nivel: group.group_nivel,
      identify: group.group_identify,
      title: 'Editar Registro',
      action: 'edit',
    });
  };

  /**
   * Function On Click Button Advance Settings
   */
  onClickButtonAdvanceSettings = () => {
    this.setState({
      displayFormAdvanceSettings: true,
    });
  };

  /**
   * Function Get List Group
   */
  async getData(queryString = 'page=1&limit=10') {
    this.setState({ progressBar: true });

    const response = await getListGroup(queryString);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.setState({
      progressBar: false,
      displayForm: false,
      displayList: true,
      rows: response.rows,
      totalRecord: response.totalRecord,
    });
  }

  /**
   * Function Table Change Handler
   * @param {object} data
   */
  tableChangeHandler = (data) => {
    const queryString = Object.keys(data)
      .map((key) => {
        switch (key) {
          case 'page_number':
            return `${encodeURIComponent('page')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'page_size':
            return `${encodeURIComponent('limit')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'sort_order':
            return data[key]
              ? `${encodeURIComponent('sort')}=${encodeURIComponent(
                  data[key].order
                )}`
              : '';
          case 'filter_value':
            return data[key]
              ? `${encodeURIComponent('search')}=${encodeURIComponent(
                  data[key]
                )}`
              : '';
          default:
            return '';
        }
      })
      .filter((value) => !!value)
      .join('&');
    this.getData(queryString);
  };

  /**
   * Function Insert Group
   */
  async insertGroup() {
    const { name, nivel, identify } = this.state;

    this.setState({ progressBar: true });

    const response = await insertGroup({
      name,
      nivel,
      identify,
    });

    if (!response.status) {
      this.setState({ progressBar: false });
      toastrMessage('error', response.message);
      return;
    }

    this.getData();
    toastrMessage('success', response.message);
  }

  /**
   * Function Update Group
   */
  async update() {
    const { groupId, name, nivel, identify } = this.state;

    this.setState({ progressBar: true });

    const response = await updateGroup({
      id: groupId,
      name,
      nivel,
      identify,
    });

    if (!response.status) {
      this.setState({ progressBar: false });
      toastrMessage('error', response.message);
      return;
    }

    this.getData();
    toastrMessage('success', response.message);
  }

  /**
   * Function Delete Group
   */
  async delete(group) {
    if (window.confirm('Você deseja excluir esse registro?')) {
      this.setState({ progressBar: true });

      const response = await deleteGroup(group.id);

      if (!response.status) {
        this.setState({ progressBar: false });
        toastrMessage('error', response.message);
        return;
      }

      this.getData();
      toastrMessage('success', response.message);
    }
  }

  /**
   * Handle Change Form
   * @param {event} e
   */
  handleChangeForm(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /**
   * Handle Submit Form
   * @param {event} e
   */
  async handleSubmitForm(e) {
    e.preventDefault();

    const { action } = this.state;

    switch (action) {
      case 'edit':
        this.update();
        break;

      default:
        this.insertGroup();
        break;
    }
  }

  /**
   * Render Component
   */
  render() {
    const {
      system,
      page,
      description,
      title,
      name,
      nivel,
      identify,
      progressBar,
      displayList,
      displayForm,
      displayFormAdvanceSettings,
      columns,
      rows,
      totalRecord,
    } = this.state;

    return (
      <Layout
        page={page}
        system={system}
        description={description}
        progressBar={progressBar}
      >
        <Content title={title}>
          {displayList && (
            <Datatables
              permissions={page}
              columns={columns}
              rows={rows}
              filter
              btnAddDefault
              btnEditDefault
              btnDeleteDefault
              loading={progressBar}
              insertRecord={(e) => this.onClickButtonInsert(e)}
              editRecord={(e) => this.onClickButtonEdit(e)}
              deleteRecord={(e) => this.delete(e)}
              dynamic
              totalRecord={totalRecord}
              onChange={(e) => this.tableChangeHandler(e)}
            />
          )}

          {displayForm && (
            <FormGroup
              name={name}
              nivel={nivel}
              identify={identify}
              displayFormAdvanceSettings={displayFormAdvanceSettings}
              onClickButtonAdvanceSettings={(e) =>
                this.onClickButtonAdvanceSettings(e)
              }
              handleChange={(e) => this.handleChangeForm(e)}
              handleSubmit={(e) => this.handleSubmitForm(e)}
              onClickReturnPage={this.onClickButtonReturnPage}
            />
          )}
        </Content>
      </Layout>
    );
  }
}

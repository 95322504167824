import React from 'react';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';
import { maskCPF } from '../../../../utils';
import { CHANNEL_ATTENDANCE } from '../../../../constants';

const FormInsuranceAttendance = ({
  handleSubmit,
  action,
  protocol,
  handleChange,
  disabled,
  date,
  name,
  cpf,
  phone,
  email,
  channelName,
  typeId,
  comment,
  answer,
  statusId,
  onClickReturnPage,
  typesAttendance,
}) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal" onSubmit={handleSubmit}>
          {action === 'edit' && (
            <>
              <div className="form-group">
                <label className="col-sm-2 control-label">Protocolo *</label>
                <div className="col-sm-4">
                  <input
                    name="protocol"
                    value={protocol}
                    onChange={handleChange}
                    type="text"
                    placeholder="Protocolo"
                    className="form-control"
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">
                  Data do Atendimento: *
                </label>
                <div className="col-sm-4">
                  <input
                    name="date"
                    value={date}
                    onChange={handleChange}
                    type="text"
                    placeholder="Data de Atendimento"
                    className="form-control"
                    disabled={disabled}
                  />
                </div>
              </div>
            </>
          )}
          <div className="form-group">
            <label className="col-sm-2 control-label">Nome *</label>
            <div className="col-sm-4">
              <input
                name="name"
                value={name}
                onChange={handleChange}
                type="text"
                placeholder="Nome do Cliente"
                className="form-control"
                disabled={disabled}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">CPF *</label>
            <div className="col-sm-4">
              <NumberFormat
                type="text"
                name="cpf"
                className="form-control"
                value={cpf}
                onChange={handleChange}
                format={maskCPF}
                placeholder="CPF"
                maxLength={14}
                disabled={disabled}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Linha do Telefone *
            </label>
            <div className="col-sm-4">
              <NumberFormat
                className="form-control"
                value={phone}
                onChange={handleChange}
                type="text"
                name="phone"
                placeholder="Telefone Fixo"
                format="(##) ####-####"
                mask="_"
                disabled={disabled}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">E-mail *</label>
            <div className="col-sm-4">
              <input
                name="email"
                value={email}
                onChange={handleChange}
                type="email"
                placeholder="E-mail"
                className="form-control"
                disabled={disabled}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Canal do Atendimento *
            </label>
            <div className="col-sm-4">
              <select
                name="channelName"
                value={channelName}
                onChange={handleChange}
                className="form-control"
                disabled={disabled}
                required
              >
                <option value="">[Selecione uma opção]</option>
                {CHANNEL_ATTENDANCE.map((response) => (
                  <option value={response}>{response}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Tipo de Atendimento *
            </label>
            <div className="col-sm-4">
              <select
                name="typeId"
                value={typeId}
                onChange={handleChange}
                className="form-control"
                disabled={disabled}
                required
              >
                <option value="">[Selecione uma opção]</option>
                {typesAttendance.map((response) => (
                  <option value={response.id}>{response.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Atendimento *</label>
            <div className="col-sm-4">
              <textarea
                className="form-control"
                value={comment}
                onChange={handleChange}
                name="comment"
                rows={10}
                disabled={disabled}
                required
              />
            </div>
          </div>

          {action === 'edit' && (
            <>
              <div className="form-group">
                <label className="col-sm-2 control-label">Responder *</label>
                <div className="col-sm-4">
                  <textarea
                    className="form-control"
                    value={answer}
                    onChange={handleChange}
                    name="answer"
                    rows={10}
                    disabled={false}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">
                  Status do Atendimento *
                </label>
                <div className="col-sm-4">
                  <select
                    name="statusId"
                    value={statusId}
                    onChange={handleChange}
                    className="form-control"
                    disabled={false}
                    required
                  >
                    <option value="">[Selecione uma opção]</option>
                    <option value="1">Manter em Aberto</option>
                    <option value="2">Resolvido</option>
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              <button
                className="btn btn-white"
                style={{ marginRight: 5 }}
                type="button"
                onClick={() => onClickReturnPage()}
              >
                Voltar
              </button>
              <button className="btn btn-primary" type="submit">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

FormInsuranceAttendance.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  typesAttendance: PropTypes.array.isRequired,
  onClickReturnPage: PropTypes.func.isRequired,
};

export default FormInsuranceAttendance;

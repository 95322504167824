/* eslint-disable */
import axios from 'axios';
import moment from 'moment';

import { validateToken } from '../../../utils';

import { URL_BACKEND } from '../../../constants';

const HEADERS = {
  'Content-Type': 'application/json',
  'X-FS-Brand-Name': 'seguros',
  'X-FS-Requester-Name': 'cs-callcenter-frontend',
  Authorization: `Bearer ${validateToken('token')}`,
};

/**
 * Function Get Search Callcenter
 * @param {string} type
 * @param {int} value
 */
export async function getSearchCallcenter(type, value) {
  value = type === 'phone' ? `55${value}` : value;

  try {
    const response = await axios.get(
      `${URL_BACKEND}/bff/api/v2/insurance/callcenter/client/search/${type}/${value}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-FS-Brand-Name': 'seguros',
          'X-FS-Requester-Name': 'cs-callcenter-frontend',
          Authorization: `Bearer ${validateToken('token')}`,
        },
      }
    );
    const users = [];

    response.data.data.forEach((response) => {
      const bundle = [];
      response.policies.forEach((response) => {
        const payment = [];
        const duplicit = [];
        response.billing_order.forEach((response) => {
          response.payment_status_return_file.forEach((response) => {
            const event_date =
              response.event_date !== null
                ? moment(response.event_date).format('DD/MM/YYYY')
                : '';
            const dueDate =
              response.due_date !== ''
                ? moment(response.due_date).format('DD/MM/YYYY')
                : '';
            const idx = duplicit.indexOf(
              response.event_date.concat(response.billing_code)
            );

            if (idx === -1) {
              payment.push({
                id: response.id,
                paymentData: event_date,
                dueDate,
                acoes: response.billing_code,
                sort: response.event_date,
              });
              duplicit.push(response.event_date.concat(response.billing_code));
            }
          });
        });
        payment.sort((a, b) => parseInt(b.sort) - parseInt(a.sort));
        const createdAt =
          response.created_at !== null
            ? moment(response.created_at).format('DD/MM/YYYY')
            : '';

        const status = {
          ACTIVE: 'ATIVO',
          PENDING: 'PENDENTE DE CONFIRMAÇÃO',
          PENDING_PAYMENT: 'PENDENTE DE PAGAMENTO',
          CANCELED: 'CANCELADO',
        };

        bundle.push({
          id: response.id,
          name: response.bundle.name || '',
          value:
            response.bundle.value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }) || '',
          phone: response.phone || '',
          createdAt,
          statusSubscription: status[response.actived] ?? 'CANCELADO',
          validityStart:
            response.incio_vigencia !== null
              ? moment(response.incio_vigencia).format('DD/MM/YYYY')
              : '',
          validityEnd:
            response.final_vigencia !== null
              ? moment(response.final_vigencia).format('DD/MM/YYYY')
              : '',
          luckNumber:
            response.luck_number.length > 0
              ? response.luck_number[0].lotery_number
              : '',
          subscriptionId: response.subscription_id || '',
          insurancePolicyNumber: response.insurance_policy_number || '',
          billingOrder: payment,
          extref: response.extref,
          client_id: response.client_id,
          channelName: response.identifier
            ? response.identifier.description
            : 'N/I',
        });
      });

      const cpf = response.cpf
        ? response.cpf.toString().length < 11
          ? response.cpf.toString().padStart(11, 0)
          : response.cpf.toString()
        : '';

      const phone = response.phone
        ? response.phone.toString().length > 10
          ? response.phone.toString().substr(2)
          : response.phone.toString()
        : '';

      const msisdn = response.msisdn
        ? response.msisdn.toString().length > 10
          ? response.msisdn.toString().substr(2)
          : response.msisdn.toString()
        : '';

      const cep = response.address.cep
        ? response.address.cep.length < 8
          ? response.address.cep.padStart(8, 0)
          : response.address.cep.toString()
        : '';

      const birthDate =
        response.birth_date !== null
          ? moment(response.birth_date).format('DD/MM/YYYY')
          : '';

      users.push({
        id: response.id,
        pxUserId: response.px_user_id,
        fullName: response.fullname || '',
        cpf,
        email: response.email || '',
        birthDate,
        msisdn,
        phone,
        rg: response.rg || '',
        gender: response.gender || '',
        maritalStatus: response.marital_status || '',
        cep,
        address: response.address.description || '',
        number: response.address_number || '',
        complement: response.address_complement || '',
        neighborhood: response.address.neighborhood.description || '',
        city: response.address.neighborhood.city.description || '',
        state: response.address.neighborhood.city.uf.code || '',
        bundle,
      });
    });

    return {
      status: true,
      users,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Send Edit User Info Callcenter
 * @param {object} body
 */
export async function sendEditUserInfoCallcenter(
  userId,
  fullName,
  date,
  landline,
  msisdn,
  cpf,
  email,
  gender,
  rg,
  maritalStatus,
  complement,
  number,
  cep,
  address,
  neighborhood,
  city,
  state
) {
  const body = {
    id: userId,
    fullname: fullName,
    birth_date: date,
    phone: landline,
    cpf: cpf,
    email: email,
    gender: gender,
    partner: 'vivo fixa',
    rg: rg,
    marital_status: maritalStatus,
    address_complement: complement,
    address_number: number,
    address: {
      cep: cep,
      description: address,
      neighborhood: {
        description: neighborhood,
        city: {
          description: city,
          uf: {
            description: state,
            code: state,
          },
        },
      },
    },
  };
  if (msisdn !== null) {
    body['msisdn'] = parseInt(msisdn, 10);
  }

  try {
    await axios.put(
      `${URL_BACKEND}/bff/api/v2/insurance/callcenter/client`,
      body,
      { headers: HEADERS }
    );
    return { status: true, message: 'Registro alterado com sucesso!' };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/* eslint-disable */
import React, { Component } from 'react';
import Loader from '../../Loader';
import { validateToken, removeToken } from '../../../utils';

export default class ButtonLogout extends Component {
  constructor() {
    super();

    this.state = {
      loader: false,
    };
  }

  /**
   * Handle Click Logout
   */
  handleLogoutClick(e) {
    e.preventDefault();

    if (validateToken('token')) {
      this.setState({
        loader: true,
      });

      setTimeout(() => {
        removeToken();
      }, 3000);
    }
  }

  /**
   * Render Component
   */
  render() {
    const { loader } = this.state;
    return (
      <>
        <Loader display={loader} />
        <a
          href="/#"
          onClick={(e) => this.handleLogoutClick(e)}
          style={{ color: '#fff' }}
        >
          <i className="fa fa-sign-out" /> Sair
        </a>
      </>
    );
  }
}

/* eslint-disable */
import React, { Component } from 'react';
import Layout from '../../../layout';
import Content from '../../../components/Content';
import Datatables from '../../../components/Datatables';
import FormProfile from '../../../components/Forms/Settings/Profile';

import {
  getListProfile,
  getListSubGroup,
  insertProfile,
  updateProfile,
  deleteProfile,
} from '../../../services';

import { validateToken, toastrMessage } from '../../../utils';

export default class Profile extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      system: 'settings',
      page: 'profile',
      description: 'Configurações :: Perfis',
      title: 'Listagem dos Perfis',
      progressBar: false,
      displayList: true,
      displayForm: false,
      displayFormUpdate: false,
      action: '',
      name: '',
      subGroup: '',
      profileId: '',
      permission: [],
      columns: {
        id: '#',
        name: 'NOME',
        group_name: 'GRUPO',
        sub_group_name: 'SUBGRUPO',
        group_identify: 'IDENTIFICADOR',
      },
      subGroupList: [],
      totalRecord: 0,
      profileList: [],
      checkbox: new Map(),
    };

    this.baseState = this.state;
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleChangeFormPermission = this.handleChangeFormPermission.bind(
      this
    );
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getListProfile();
    this.getListSubGroup();
    this.validateToken();
  }

  /**
   * On Click Return Page
   */
  onClickReturnPage = () => {
    this.setState({
      name: '',
      subGroup: '',
      displayList: true,
      displayForm: false,
    });
  };

  /**
   * Function Insert Record
   */
  onClickInsertRecord = () => {
    this.setState({
      action: 'insert',
      title: 'Inserir Registro',
      displayList: false,
      displayForm: true,
    });
  };

  /**
   * Function Edit Record
   */
  onClickEditRecord = (record) => {
    this.setState({
      action: 'edit',
      title: 'Editar Registro',
      displayList: false,
      displayForm: true,
      name: record.name,
      profileId: record.id,
      subGroup: record.sub_group,
    });
  };

  /**
   * Function get List Profile
   */
  async getListProfile(queryString = 'page=1&limit=10') {
    this.setState({ progressBar: true });

    const response = await getListProfile(queryString);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.setState({
      loader: false,
      progressBar: false,
      displayForm: false,
      displayList: true,
      profileList: response.rows,
      totalRecord: response.totalRecord,
    });
  }

  /**
   * Function get List Sub Group
   */
  async getListSubGroup(queryString = 'page=1&limit=1000') {
    this.setState({ progressBar: true });

    const response = await getListSubGroup(queryString);

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    const data = [];

    response.rows.forEach((row) => {
      data.push({
        id: row.id,
        name: `${row.group_name} | ${row.name}`,
      });
    });

    this.setState({
      progressBar: false,
      subGroupList: data,
    });
  }

  /**
   * Function Table Change Handler
   * @param {object} data
   */
  tableChangeHandler = (data) => {
    const queryString = Object.keys(data)
      .map((key) => {
        switch (key) {
          case 'page_number':
            return `${encodeURIComponent('page')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'page_size':
            return `${encodeURIComponent('limit')}=${encodeURIComponent(
              data[key]
            )}`;
          case 'sort_order':
            return data[key]
              ? `${encodeURIComponent('sort')}=${encodeURIComponent(
                  data[key].order
                )}`
              : '';
          case 'filter_value':
            return data[key]
              ? `${encodeURIComponent('search')}=${encodeURIComponent(
                  data[key]
                )}`
              : '';
          default:
            return '';
        }
      })
      .filter((value) => !!value)
      .join('&');
    this.getListProfile(queryString);
  };

  /**
   * Function Validate Token
   */
  async validateToken() {
    const response = validateToken('decode_token');

    if (!response) {
      this.setState(this.baseState);
      return;
    }

    this.setState({
      permission: response.permission,
    });
  }

  /**
   * Function Insert Profile
   */
  async insertProfile(permission) {
    const { name, subGroup } = this.state;

    this.setState({ progressBar: true });

    const response = await insertProfile({
      name,
      sub_group: subGroup,
      permission,
    });

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.setState({ permission: [], checkbox: new Map() });
    this.getListProfile();
    this.validateToken();
    toastrMessage('success', response.message);
  }

  /**
   * Function Insert Profile
   */
  async updateProfile(permission) {
    const { name, subGroup, profileId } = this.state;

    this.setState({ progressBar: true });

    const response = await updateProfile({
      id: profileId,
      name,
      sub_group: subGroup.value,
      permission,
    });

    if (!response.status) {
      toastrMessage('error', response.message);
      this.setState({ progressBar: false });
      return;
    }

    this.setState({ permission: [], checkbox: new Map() });
    this.getListProfile();
    this.validateToken();
    toastrMessage('success', response.message);
  }

  /**
   * Function Delete Profile
   */
  async delete(record) {
    if (window.confirm('Você deseja excluir esse registro?')) {
      this.setState({ progressBar: true });

      const response = await deleteProfile(record.id);

      if (!response.status) {
        this.setState({ progressBar: false });
        toastrMessage('error', response.message);
        return;
      }

      toastrMessage('success', response.message);
      this.getListProfile();
    }
  }

  /**
   * Handle Change Form Send Username and Password
   * @param {event} e
   */
  handleChangeForm(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Handle Change Select Group
   * @param {int} subGroup
   */
  handleChangeSelectGroup = (subGroup) => {
    this.setState({ subGroup });
  };

  /**
   * Handle Change Form Send Permission
   * @param {event} e
   */
  handleChangeFormPermission(e) {
    const item = e.target.value;
    const isChecked = e.target.checked ? 1 : 0;

    this.setState((prevState) => ({
      checkbox: prevState.checkbox.set(item, isChecked),
    }));
  }

  /**
   * Handle Submit Form Send Username and Password
   * @param {event} e
   */
  async handleSubmitForm(e) {
    e.preventDefault();

    const { action, subGroup, checkbox } = this.state;

    if (!subGroup) {
      toastrMessage(
        'error',
        'Selecione o campo Sub-Grupo, pois é obrigatório!'
      );
      return;
    }

    const data = [
      { id: 0, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 1, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 2, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 3, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 4, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 5, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 6, list: 0, create: 0, edit: 0, delete: 0 },
      { id: 7, list: 0, create: 0, edit: 0, delete: 0 },
    ];

    data[66] = { id: 66, list: 0, create: 0, edit: 0, delete: 0 };

    checkbox.forEach((key, value) => {
      const [id, action] = value.split(',');
      data[id][action] = key;
    });

    data.splice(0, 1);

    const permission = [];

    Object.values(data).forEach((value) => {
      permission.push(value);
    });

    switch (action) {
      case 'edit':
        this.updateProfile(permission);
        break;

      default:
        this.insertProfile(permission);
        break;
    }
  }

  /**
   * Render Component
   */
  render() {
    const {
      system,
      page,
      description,
      title,
      progressBar,
      name,
      subGroup,
      permission,
      displayList,
      displayForm,
      columns,
      subGroupList,
      profileList,
      totalRecord,
    } = this.state;

    return (
      <Layout
        system={system}
        page={page}
        description={description}
        progressBar={progressBar}
      >
        <Content title={title}>
          {displayList && (
            <Datatables
              permissions={page}
              columns={columns}
              rows={profileList}
              loading={progressBar}
              filter
              btnAddDefault
              btnEditDefault
              btnDeleteDefault
              insertRecord={(e) => this.onClickInsertRecord(e)}
              editRecord={(e) => this.onClickEditRecord(e)}
              deleteRecord={(e) => this.delete(e)}
              dynamic
              totalRecord={totalRecord}
              onChange={(e) => this.tableChangeHandler(e)}
            />
          )}
          {displayForm && (
            <FormProfile
              name={name}
              subGroup={subGroup}
              permission={permission}
              rows={subGroupList}
              handleChange={this.handleChangeForm}
              handleChangeSelectGroup={this.handleChangeSelectGroup}
              handleChangeFormPermission={this.handleChangeFormPermission}
              handleSubmit={this.handleSubmitForm}
              onClickReturnPage={this.onClickReturnPage}
            />
          )}
        </Content>
      </Layout>
    );
  }
}

const CONFIG = {
  system: 'search',
  page: 'search',
  description: 'Seguros :: Pesquisa',
  title: 'Consultar Cliente',
};

const DISPLAY = {
  page: true,
  formSearch: true,
  formTableClient: false,
  tabsInsurance: false,
  tabsUserInfo: false,
  tabsPayments: false,
  tabsEditUserInfo: false,
  tabsSale: false,
  tabsConfirmSale: false,
  tabsSuccessSale: false,
};

const FORM_DATA = {
  type: '',
  search: '',
  cpf: '',
  phone: '',
  protocolStatus: '',
  protocol: '',
  dateProtocol: '',
  userId: '',
  fullName: '',
  birthDate: '',
  msisdn: '',
  rg: '',
  email: '',
  gender: '',
  maritalStatus: '',
  cep: '',
  address: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
};

const FORM_SALE = {
  productName: '',
  productPrice: '',
  campaignId: '',
};

const COLUMNS_USERS = {
  fullName: 'NOME',
  cpf: 'CPF',
  phone: 'TELEFONE',
};

const COLUMNS_PRODUCTS = {
  id: '#',
  phone: 'LINHA SEGURADA',
  channelName: 'CANAL',
  name: 'PRODUTO',
  value: 'VALOR',
  createdAt: 'DATA DA ADESÃO',
  validityStart: 'INICIO DA VIGENCIA',
  validityEnd: 'FIM DA VIGENCIA',
  luckNumber: 'NUMERO DA SORTE',
  statusSubscription: 'STATUS',
};

const COLUMNS_PROTOCOL = {
  id: '#',
  attendanceName: 'CRIADO POR',
  protocol: 'PROTOCOLO',
  createdAt: 'DATA DE ABERTURA',
  updatedAt: 'DATA DE FECHAMENTO',
  action: 'AÇÕES',
  status: 'STATUS',
};

const COLUMNS_ATTENDANCE = {
  id: '#',
  protocol: 'PROTOCOLO',
  name: 'NOME DO CLIENTE',
  date: 'DATA',
  typeName: 'TIPO',
  channelName: 'CANAL',
  statusAttendance: 'STATUS',
};

const COLUMNS_PAYMENT = {
  id: '#',
  paymentData: 'DATA DE EVENTO',
  dueDate: 'DATA DE VENCIMENTO',
  acoes: 'AÇOES',
};

const TAB_INDEX = 0;

export {
  CONFIG,
  DISPLAY,
  FORM_DATA,
  FORM_SALE,
  COLUMNS_USERS,
  COLUMNS_PRODUCTS,
  COLUMNS_PROTOCOL,
  COLUMNS_ATTENDANCE,
  COLUMNS_PAYMENT,
  TAB_INDEX,
};

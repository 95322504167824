import { TYPES_ATTENDANCE } from '../../../constants';

/**
 * Function Validator Types Attendance
 * @param {int} type
 */
export function validatorTypeAttendance(type) {
  let filter = '';
  const types = TYPES_ATTENDANCE;

  types.forEach((response) => {
    if (response.id === parseInt(type, 10)) {
      filter = response.name;
    }
  });

  return filter;
}

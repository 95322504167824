/* eslint-disable no-alert */
import React, { useEffect, useState, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Layout from '../../../layout';
import Content from '../../../components/Content';
import Container from '../../../components/Container';
import ContainerTabs from '../../../components/ContainerTabs';
import FormInsuranceSendSearch from '../../../components/Forms/Insurance/Sale/SendSearch';
import Datatables from '../../../components/Datatables';
import TableInsuranceSale from '../../../components/Tables/Insurance/Sale';
import FormInsuranceConfirmSale from '../../../components/Forms/Insurance/Sale/ConfirmSale';
import FormInsuranceSuccessSale from '../../../components/Forms/Insurance/Sale/SuccessSale';
import FormInsuranceSendEditUserInfo from '../../../components/Forms/Insurance/Sale/SendSale';

import {
  createProtocol,
  validateProtocol,
  validateToken,
  validatorLandline,
  validatorCPF,
  toastrMessage,
  validatorDate,
  // validatorCEP,
  validatorPhoneNumber,
  validatorPermissions,
} from '../../../utils';

import {
  sendProtocol,
  getProtocolCallcenter,
  getListAttendanceByPhone,
  sendProtocolCallcenter,
  getSearchCallcenter,
  subscribeCallcenter,
  unSubscribeCallcenter,
  sendEditUserInfoCallcenter,
  getTypeAttendance,
} from '../../../services';

import {
  CONFIG,
  DISPLAY,
  FORM_DATA,
  FORM_SALE,
  COLUMNS_USERS,
  COLUMNS_PRODUCTS,
  COLUMNS_ATTENDANCE,
  COLUMNS_PAYMENT,
  COLUMNS_PROTOCOL,
  TAB_INDEX,
} from './settings';

const Search = () => {
  const [progressBar, setProgressBar] = useState(false);
  const [display, setDisplay] = useState(DISPLAY);
  const [formData, setFormData] = useState(FORM_DATA);
  const [formSale, setFormSale] = useState(FORM_SALE);
  const [tabIndex, setTabIndex] = useState(TAB_INDEX);
  const [userData, setUserData] = useState([]);
  const [protocolData, setProtocolData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);

  /**
   * Function Validate Protocol
   */
  const verifyProtocol = useCallback(async () => {
    if (validateProtocol()) {
      setProgressBar(true);
      setDisplay({
        ...display,
        page: false,
        formSearch: false,
        tabsEditUserInfo: false,
      });

      const response1 = await getSearchCallcenter(
        'phone',
        validateProtocol('phone')
      );

      if (!response1.status) {
        toastrMessage('error', response1.message);
        setProgressBar(false);
        return;
      }

      const response2 = await getProtocolCallcenter(
        response1.users[0].pxUserId
      );

      if (!response2.status) {
        setProgressBar(false);

        toastrMessage('error', response2.message);
        return;
      }

      setDisplay({
        ...display,
        page: true,
        formSearch: false,
        tabsInsurance: true,
        tabsUserInfo: true,
        tabsSale: true,
        tabsEditUserInfo: false,
      });

      setTabIndex(0);
      setProgressBar(false);
      setProductsData(response1.users[0].bundle);
      setProtocolData(response2.rows);

      setFormData({
        ...formData,
        protocol: validateProtocol('protocol'),
        userId: response1.users[0].id,
        fullName: response1.users[0].fullName,
        birthDate: response1.users[0].birthDate,
        msisdn: response1.users[0].msisdn,
        phone: response1.users[0].phone,
        cpf: response1.users[0].cpf,
        rg: response1.users[0].rg,
        email: response1.users[0].email,
        gender: response1.users[0].gender,
        maritalStatus: response1.users[0].maritalStatus,
        cep: response1.users[0].cep,
        address: response1.users[0].address,
        number: response1.users[0].number,
        complement: response1.users[0].complement,
        neighborhood: response1.users[0].neighborhood,
        city: response1.users[0].city,
        state: response1.users[0].state,
      });
    }
  });

  /**
   * Function Handle Change Form
   * @param {event} e
   */
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Function Handle Submit Form Send Search
   * @param {event} e
   */
  const handleSubmitFormSendSearch = async (e) => {
    e.preventDefault();
    setProgressBar(true);

    if (formData.type === 'phone') {
      const validator = validatorLandline(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.landline;
    }

    if (formData.type === 'cpf') {
      const validator = validatorCPF(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.cpf;
    }

    const response = await getSearchCallcenter(
      formData.type,
      parseInt(formData.search, 10)
    );

    if (!response.status) {
      toastrMessage('error', response.message);
      setProgressBar(false);
      return;
    }

    if (response.users.length === 0) {
      toastrMessage('error', 'Cliente não encontrado. Tente novamente!');
      setProgressBar(false);
      return;
    }

    setProgressBar(false);

    setDisplay({
      ...display,
      formTableClient: true,
    });

    setUserData(response.users);

    setFormData({
      ...formData,
      cpf: formData.type === 'cpf' ? formData.search : '',
      phone: formData.type === 'phone' ? formData.search : '',
    });
  };

  /**
   * Function Handle Submit Form Register Client
   * @param {event} e
   */
  const handleSubmitFormEditUserInfo = async (e) => {
    e.preventDefault();

    const validator2 = validatorLandline(formData.phone);

    if (!validator2.status) {
      toastrMessage('error', validator2.message);
      setProgressBar(false);
      return;
    }

    const validator3 = validatorDate(formData.birthDate);

    if (!validator3.status) {
      toastrMessage('error', validator3.message);
      setProgressBar(false);
      return;
    }

    const validator4 = validatorCPF(formData.cpf);

    if (!validator4.status) {
      toastrMessage('error', validator4.message);
      setProgressBar(false);
      return;
    }

    const validator5 = validatorPhoneNumber(formData.msisdn);

    if (!validator5.status) {
      toastrMessage('error', validator5.message);
      setProgressBar(false);
      return;
    }

    setProgressBar(true);

    const sendEditUserInfoCallcenterResponse = await sendEditUserInfoCallcenter(
      formData.userId,
      formData.fullName,
      validator3.date,
      `55${validator2.landline}`,
      validator5.msisdn.length === 0 ? null : `55${validator5.msisdn}`,
      validator4.cpf,
      formData.email,
      formData.gender,
      formData.rg,
      formData.maritalStatus,
      formData.complement,
      formData.number,
      formData.cep,
      formData.address,
      formData.neighborhood,
      formData.city,
      formData.state
    );

    if (!sendEditUserInfoCallcenterResponse.status) {
      toastrMessage('error', sendEditUserInfoCallcenterResponse.message);
      setProgressBar(false);
      return;
    }

    toastrMessage('success', sendEditUserInfoCallcenterResponse.message);
    verifyProtocol();

    setTimeout(() => {
      setDisplay({
        ...display,
        tabsUserInfo: true,
        tabsEditUserInfo: false,
      });
    }, 2000);
  };

  /**
   * Function On Click Button History Product
   * @param {event} e
   * @param {object} record
   */
  const onClickButtonHistoryOrSale = async (e, record) => {
    e.preventDefault();
    setProgressBar(true);

    const response1 = await getProtocolCallcenter(record.pxUserId);

    if (!response1.status) {
      toastrMessage('error', response1.message);
      setProgressBar(false);
      return;
    }

    const response2 = await sendProtocol({
      identify: validateToken('userIdentity'),
      event: 'CALL_CENTER',
    });

    if (!response2.status) {
      toastrMessage('error', response2.message);
      setProgressBar(false);
      return;
    }

    const response3 = await sendProtocolCallcenter({
      protocol: response2.protocol,
      user: record.pxUserId,
      callcenter_userid: validateToken('callcenterUserId'),
      status: 'OPEN',
      action: 'SEARCH',
    });

    if (!response3.status) {
      toastrMessage('error', response3.message);
      setProgressBar(false);
      return;
    }

    setProtocolData(response1.rows);
    setProductsData(record.bundle);

    setFormData({
      ...formData,
      protocolStatus: true,
      protocol: response2.protocol,
      dateProtocol: response3.dateProtocol,
      userId: record.id,
      fullName: record.fullName,
      birthDate: record.birthDate,
      msisdn: record.msisdn,
      phone: record.phone,
      cpf: record.cpf,
      rg: record.rg,
      email: record.email,
      gender: record.gender,
      maritalStatus: record.maritalStatus,
      cep: record.cep,
      address: record.address,
      number: record.number,
      complement: record.complement,
      neighborhood: record.neighborhood,
      city: record.city,
      state: record.state,
    });

    setDisplay({
      ...display,
      page: false,
      formSearch: false,
      formTableClient: false,
    });

    createProtocol({
      phone: `${record.phone}`,
      fullName: record.fullName,
      protocol: response2.protocol,
      dateProtocol: response3.dateProtocol,
    });

    window.location = '/search';
  };

  /**
   * Function On Click Button Subscription
   * @param {event} e
   */
  const onClickButtonSubscription = async (e) => {
    e.preventDefault();

    if (window.confirm('Você realmente deseja confirmar esta venda?')) {
      setProgressBar(true);

      const subscribeCallcenterResponse = await subscribeCallcenter(
        validateToken('userIdentity'),
        validateToken('callcenterUserId'),
        formSale.campaignId,
        formData.fullName,
        formData.birthDate,
        formData.phone,
        formData.msisdn,
        formData.cpf,
        formData.email,
        formData.gender,
        formData.rg,
        formData.protocol,
        formData.maritalStatus,
        formData.complement,
        formData.number,
        formData.cep,
        formData.address,
        formData.neighborhood,
        formData.city,
        formData.state
      );

      if (subscribeCallcenterResponse.status === 'info') {
        toastrMessage('warning', subscribeCallcenterResponse.message);
        setProgressBar(false);
        return;
      }

      if (!subscribeCallcenterResponse.status) {
        toastrMessage('error', subscribeCallcenterResponse.message);
        setProgressBar(false);
        return;
      }

      const sendProtocolCallcenterResponse = await sendProtocolCallcenter({
        protocol: formData.protocol,
        user: formData.userId,
        callcenter_userid: validateToken('callcenterUserId'),
        offer: 1234,
        status: 'OPEN',
        action: 'SUBSCRIPTION',
      });

      if (!sendProtocolCallcenterResponse.status) {
        toastrMessage('error', sendProtocolCallcenterResponse.message);
        setProgressBar(false);
        return;
      }

      setTimeout(() => {
        setProgressBar(false);
        setDisplay({
          ...display,
          tabsConfirmSale: false,
          tabsSuccessSale: true,
        });
      }, 1500);
    }
  };

  /**
   * Function On Click Button Unsubscription
   * @param {event} e
   * @param {object} record
   */
  const onClickButtonUnSubscription = async (e, record) => {
    e.preventDefault();

    if (window.confirm('Você realmente deseja cancelar esta cobrança?')) {
      setProgressBar(true);
      const response = await unSubscribeCallcenter(
        record.subscriptionId,
        record.insurancePolicyNumber,
        validateProtocol('protocol'),
        validateToken('callcenterUserId'),
        record.client_id
      );

      if (!response.status) {
        toastrMessage('error', response.message);
        setProgressBar(false);
        return;
      }

      toastrMessage('success', 'Produto cancelado com sucesso!');
      setProgressBar(false);

      setTimeout(() => {
        window.location = '/search';
      }, 1500);
    }
  };

  /**
   * Function On Click Button History Payment
   * @param {event} e
   * @param {object} record
   */
  const onClickButtonHistoryPayment = (e, record) => {
    e.preventDefault();

    setProgressBar(true);

    setTimeout(() => {
      setProgressBar(false);
      setPaymentData(record.billingOrder);
      setDisplay({
        ...display,
        tabsPayments: true,
      });
    }, 1500);
  };

  /**
   * Function On Click Button Form Send Sale
   * @param {event} e
   */
  const onClickButtonSelectProduct = (e, product) => {
    e.preventDefault();
    setProgressBar(true);

    setTimeout(() => {
      setProgressBar(false);

      setFormSale({
        ...formSale,
        productName: product.name,
        productPrice: product.price,
        campaignId: product.id,
      });

      setDisplay({
        ...display,
        tabsSale: false,
        tabsConfirmSale: true,
      });
    }, 1500);
  };

  /**
   * Function On Click Button Back Sale Product
   */
  const onClickButtonBackSaleProduct = () => {
    verifyProtocol();

    setTimeout(() => {
      setDisplay({
        ...display,
        tabsSuccessSale: false,
      });
    }, 2000);
  };

  /**
   * Button Edit User Data
   */
  const buttonEditUserData = () =>
    validatorPermissions(CONFIG.page).has('edit') && (
      <button
        type="button"
        className="btn btn-w-m btn-primary"
        onClick={() =>
          setDisplay({
            ...display,
            tabsUserInfo: false,
            tabsEditUserInfo: true,
          })
        }
      >
        <i className="fa fa-cogs" /> Editar Dados do Cliente
      </button>
    );

  /**
   * Function On Click Select Tabs
   */
  const onClickSelectTabs = async (tab) => {
    setTabIndex(tab);

    if (tab === 1) {
      setProgressBar(true);

      const listAttendanceByPhone = await getListAttendanceByPhone(
        'phone',
        validateProtocol('phone')
      );

      if (!listAttendanceByPhone.status) {
        setProgressBar(false);
        toastrMessage('error', listAttendanceByPhone.message);
        return;
      }

      const typeAttendanceResponse = await getTypeAttendance();

      if (!typeAttendanceResponse.status) {
        setProgressBar(false);
        toastrMessage('error', typeAttendanceResponse.message);
        return;
      }

      listAttendanceByPhone.rows.forEach((attendance, i) => {
        typeAttendanceResponse.data.forEach((type) => {
          if (parseInt(attendance.typeId, 10) === type.id) {
            listAttendanceByPhone.rows[i].typeName = type.name;
          }
        });
      });

      setProgressBar(false);
      setAttendanceData(listAttendanceByPhone.rows);
    }
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    verifyProtocol();
  }, []);

  return (
    <Layout
      system={CONFIG.system}
      page={CONFIG.page}
      description={CONFIG.description}
      progressBar={progressBar}
    >
      {display.page && (
        <Content title={CONFIG.title}>
          {display.formSearch && (
            <FormInsuranceSendSearch
              page={CONFIG.page}
              type={formData.type}
              search={formData.search}
              isDisabled={progressBar}
              handleChange={(e) => handleChange(e)}
              handleSubmit={(e) => handleSubmitFormSendSearch(e)}
            />
          )}

          {display.formTableClient && (
            <Container title="Dados do Cliente *">
              <Datatables
                columns={COLUMNS_USERS}
                permissions={CONFIG.page}
                page={CONFIG.page}
                rows={userData}
                btnDeleteDefault={false}
                btnSubscription
                btnHistory
                onClickButtonHistoryOrSale={(e, record) =>
                  onClickButtonHistoryOrSale(e, record)
                }
              />
            </Container>
          )}

          {display.tabsInsurance && (
            <ContainerTabs>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(tab) => onClickSelectTabs(tab)}
              >
                <TabList>
                  <Tab>DADOS DO CLIENTE</Tab>
                  <Tab>ATENDIMENTOS</Tab>
                  <Tab>PROTOCOLOS DE ATENDIMENTOS</Tab>
                  <Tab
                    style={{
                      backgroundColor: 'rgb(237, 85, 101)',
                      color: '#fff',
                    }}
                  >
                    COMPRAR PRODUTO
                  </Tab>
                </TabList>

                <TabPanel style={{ marginTop: 25 }}>
                  {display.tabsUserInfo && (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <strong>Nome:</strong> {formData.fullName} <br />
                          <strong>CPF:</strong> {formData.cpf} <br />
                          <strong>Endereço:</strong> {formData.address}{' '}
                          {formData.number} <br />
                          <strong>Bairro:</strong> {formData.neighborhood}{' '}
                          <br />
                        </div>
                        <div className="col-md-4">
                          <strong>E-mail:</strong> {formData.email} <br />
                          <strong>Telefone:</strong> {formData.phone} <br />
                          <strong>Cidade/UF:</strong> {formData.city} /{' '}
                          {formData.state} <br />
                          <strong>CEP:</strong> {formData.cep} <br />
                        </div>
                        <div className="col-md-4">{buttonEditUserData()}</div>
                      </div>
                      <div className="hr-line-dashed" />
                      <div className="row">
                        <div className="col-md-12" style={{ marginBottom: 35 }}>
                          <Datatables
                            page={CONFIG.page}
                            permissions={CONFIG.page}
                            columns={COLUMNS_PRODUCTS}
                            rows={productsData}
                            filter
                            btnDeleteDefault={false}
                            btnHistoryPayment
                            btnCertificate
                            btnUnSubscription
                            onClickButtonUnSubscription={(e, record) =>
                              onClickButtonUnSubscription(e, record)
                            }
                            onClickButtonHistoryPayment={(e, record) =>
                              onClickButtonHistoryPayment(e, record)
                            }
                          />
                        </div>
                      </div>
                      {display.tabsPayments && (
                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-primary"
                              type="button"
                              style={{ float: 'right' }}
                              onClick={() =>
                                setDisplay({
                                  ...display,
                                  tabsPayments: false,
                                })
                              }
                            >
                              <i className="fa fa-close" />
                            </button>
                            <h4>HISTÓRICO DE PAGAMENTOS: </h4>
                            <div className="hr-line-dashed" />
                            <Datatables
                              columns={COLUMNS_PAYMENT}
                              rows={paymentData}
                              columnsActionStatus={false}
                              sortTable="sort"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {display.tabsEditUserInfo && (
                    <div className="row">
                      <div className="col-md-12">
                        <FormInsuranceSendEditUserInfo
                          fullName={formData.fullName}
                          msisdn={formData.msisdn}
                          phone={formData.phone}
                          cpf={formData.cpf}
                          rg={formData.rg}
                          email={formData.email}
                          birthDate={formData.birthDate}
                          gender={formData.gender}
                          maritalStatus={formData.maritalStatus}
                          cep={formData.cep}
                          address={formData.address}
                          number={formData.number}
                          complement={formData.complement}
                          neighborhood={formData.neighborhood}
                          city={formData.city}
                          state={formData.state}
                          // onBlurSearchCep={this.onBlurSearchCep}
                          handleChange={(e) => handleChange(e)}
                          handleSubmit={(e) => handleSubmitFormEditUserInfo(e)}
                          onClickButtonBackSendSale={() =>
                            setDisplay({
                              ...display,
                              tabsUserInfo: true,
                              tabsEditUserInfo: false,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </TabPanel>

                <TabPanel style={{ marginTop: 25 }}>
                  <Datatables
                    loading={progressBar}
                    columns={COLUMNS_ATTENDANCE}
                    rows={attendanceData}
                    permissions={CONFIG.page}
                    page={CONFIG.page}
                    btnDeleteDefault={false}
                    btnAddAttendance
                    btnEditAttendance
                    filter
                  />
                </TabPanel>

                <TabPanel style={{ marginTop: 25 }}>
                  <Datatables
                    filter
                    loading={progressBar}
                    columns={COLUMNS_PROTOCOL}
                    rows={protocolData}
                    columnsActionStatus={false}
                  />
                </TabPanel>

                <TabPanel style={{ marginTop: 25 }}>
                  {display.tabsSale && (
                    <TableInsuranceSale
                      onClickButtonSelectProduct={(e, product) =>
                        onClickButtonSelectProduct(e, product)
                      }
                    />
                  )}
                  {display.tabsConfirmSale && (
                    <FormInsuranceConfirmSale
                      productName={formSale.productName}
                      productPrice={formSale.productPrice}
                      onClickButtonBack={() =>
                        setDisplay({
                          ...display,
                          tabsSale: true,
                          tabsConfirmSale: false,
                        })
                      }
                      onClickButtonConfirmSaleProduct={(e) =>
                        onClickButtonSubscription(e)
                      }
                    />
                  )}
                  {display.tabsSuccessSale && (
                    <FormInsuranceSuccessSale
                      productName={formSale.productName}
                      onClickButtonBackSaleProduct={() =>
                        onClickButtonBackSaleProduct()
                      }
                    />
                  )}
                </TabPanel>
              </Tabs>
            </ContainerTabs>
          )}
        </Content>
      )}
    </Layout>
  );
};

export default Search;

/* eslint-disable */
import JWT from 'jsonwebtoken';

import { SECRET_KEY } from '../../constants';

/**
 * Function Create Protocol
 * @param {obj} data
 */
export function createProtocol(data) {
  return JWT.sign(
    {
      data: {
        phone: data.phone,
        fullName: data.fullName,
        cpf: data.cpf,
        email: data.email,
        protocol: data.protocol,
        dateProtocol: data.dateProtocol,
      },
    },
    SECRET_KEY,
    { expiresIn: '24h' },
    (err, protocol) => {
      if (err) {
        return false;
      }

      localStorage.setItem('protocol', protocol);
    }
  );
}

/**
 * Function Validate Protocol
 * @param {type} type
 */
export function validateProtocol(type) {
  const protocol = localStorage.getItem('protocol');

  return JWT.verify(protocol, SECRET_KEY, (err, decoded) => {
    if (err) {
      localStorage.removeItem('protocol');
      return false;
    }

    switch (type) {
      case 'phone':
        return decoded.data.phone;
      case 'fullName':
        return decoded.data.fullName;
      case 'email':
        return decoded.data.email;
      case 'cpf':
        return decoded.data.protocol;
      case 'protocol':
        return decoded.data.protocol;
      case 'dateProtocol':
        return decoded.data.dateProtocol;
      default:
        return true;
    }
  });
}

/**
 * Function Remove Protocol
 */
export function removeProtocol() {
  const token = localStorage.getItem('protocol');

  return JWT.verify(token, SECRET_KEY, (err) => {
    if (err) {
      return false;
    }
    localStorage.removeItem('protocol');
    return (window.location = '/');
  });
}

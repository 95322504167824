/* eslint-disable */
import React, { Component } from 'react';
import Layout from '../../layout';

export default class Dashboard extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      system: 'dashboard',
      page: 'dashboard',
      description: 'Central de Serviços :: Callcenter FS',
      title: 'Dashboard',
    };
  }

  /**
   * Render Component
   */
  render() {
    const { system, page, description, title } = this.state;

    return (
      <Layout
        system={system}
        page={page}
        description={description}
        title={title}
      />
    );
  }
}

/* eslint-disable */
import React from 'react';

export default (props) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal" onSubmit={props.handleSubmit}>
          <div className="form-group">
            <label className="col-sm-2 control-label">Nome *</label>
            <div className="col-sm-4">
              <input
                name="name"
                value={props.name}
                onChange={props.handleChange}
                type="text"
                placeholder="Nome"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Login *</label>
            <div className="col-sm-4">
              <input
                name="userName"
                value={props.userName}
                onChange={props.handleChange}
                type="text"
                placeholder="Login"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Senha *</label>
            <div className="col-sm-4">
              <input
                name="password"
                value={props.password}
                onChange={props.handleChange}
                type="password"
                placeholder="Senha"
                className="form-control"
                required={props.requiredPassword}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Perfil *</label>
            <div className="col-sm-4">
              <select
                className="form-control"
                onChange={props.handleChange}
                name="profileId"
                value={props.profileId}
                required
              >
                <option value="">[Selecione uma opção]</option>
                {props.profileList.map((response, key) => (
                  <option key={key} value={response.id}>
                    {response.name.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-2 control-label">Status *</label>
            <div className="col-sm-4">
              <select
                className="form-control"
                onChange={props.handleChange}
                name="status"
                value={props.status}
                required
              >
                <option>[Selecione uma opção]</option>
                <option value="ATIVO">ATIVO</option>
                <option value="INATIVO">INATIVO</option>
              </select>
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              <button
                className="btn btn-white"
                type="button"
                onClick={() => props.onClickReturnPage()}
              >
                Voltar
              </button>
              <button className="btn btn-primary" type="submit">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

const CONFIG = {
  page_size: 10,
  show_filter: true,
  show_pagination: true,
  pagination: 'advance',
  length_menu: [10, 20, 50, 100],
  button: {
    excel: false,
    print: false,
  },
  sort: { column: 'id', order: 'desc' },
  language: {
    length_menu: 'Mostrar _MENU_ registros por página',
    filter: 'Pesquisar',
    info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
    pagination: {
      first: 'Primero',
      previous: 'Anterior',
      next: 'Próximo',
      last: 'Último',
    },
    no_data_text: 'Nenhum registro encontrado',
    loading_text: 'Estamos carregando os dados',
  },
};

const BUTTONS_NAME = {
  addDefault: 'Adicionar Registro',
  editDefault: 'Editar Registro',
  deleteDefault: 'Remover Registro',
  addAttendance: 'Adicionar Novo Atendimento',
  importAttendance: 'Importar Atendentes',
  subscription: 'Comprar',
  history: 'Histórico',
  certificate: 'Exibir Certificado',
  historyPayment: 'Histórico de Pagamento',
  unsubscription: 'Cancelamento do Produto',
};

export { CONFIG, BUTTONS_NAME };

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';

const FORM_SETTINGS = {
  mask: '',
  placeholder: '',
  disabled: true,
};

const FormInsuranceSendSearch = ({
  handleChange,
  handleSubmit,
  type,
  search,
  page,
  onClickButtonBackForm,
  isDisabled,
}) => {
  const [formSettings, setFormSettings] = useState(FORM_SETTINGS);

  /**
   * Function On Blur Search Cep
   * @param {event} e
   */
  const handleChangeTypeSearch = (e) => {
    if (e.target.value === 'cpf') {
      setFormSettings({
        ...formSettings,
        mask: '###.###.###-##',
        placeholder: 'Informe o CPF',
        disabled: false,
      });
      handleChange(e);
      return;
    }

    if (e.target.value === 'phone') {
      setFormSettings({
        ...formSettings,
        mask: '(##) ####-####',
        placeholder: 'Número da Linha Segurada Vivo Fixa',
        disabled: false,
      });
      handleChange(e);
      return;
    }

    setFormSettings(FORM_SETTINGS);
  };

  useEffect(() => {
    setFormSettings(FORM_SETTINGS);
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12">
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="col-sm-2 control-label">Consultar por *</label>
            <div className="col-sm-4">
              <select
                value={type}
                name="type"
                onChange={handleChangeTypeSearch}
                className="form-control"
                required
              >
                <option value="">[Selecione uma opção]</option>
                <option value="cpf">CPF</option>
                <option value="phone">
                  Número da Linha Segurada Vivo Fixa
                </option>
              </select>
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <label className="col-sm-2 control-label">Digite o valor *</label>
            <div className="col-sm-4">
              <NumberFormat
                className="form-control"
                value={search}
                onChange={handleChange}
                type="text"
                name="search"
                disabled={formSettings.disabled}
                format={formSettings.mask}
                placeholder={formSettings.placeholder}
                required
              />
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              {page !== 'search' && (
                <button
                  className="btn btn-white"
                  type="button"
                  onClick={onClickButtonBackForm}
                  style={{ marginRight: 5 }}
                >
                  Voltar
                </button>
              )}
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isDisabled}
              >
                Consultar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

FormInsuranceSendSearch.defaultProps = {
  onClickButtonBackForm: () => {},
};

FormInsuranceSendSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  onClickButtonBackForm: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
};

export default FormInsuranceSendSearch;

/* eslint-disable */
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

/**
 * Settings
 */
import SettingsGroup from './pages/Settings/Group';
import SettingsSubGroup from './pages/Settings/SubGroup';
import SettingsUsers from './pages/Settings/Users';
import SettingsProfile from './pages/Settings/Profile';

/**
 * Insurance
 */
import InsuranceSale from './pages/Insurance/Sale';
import InsuranceSearch from './pages/Insurance/Search';
import InsuranceAttendance from './pages/Insurance/Attendance';
// import InsuranceDevolution from './pages/Insurance/Devolution'

/**
 * Authorize
 */
import { isAuthorizated } from './utils';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthorizated(props) === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/group" exact component={SettingsGroup} />
      <PrivateRoute path="/users" exact component={SettingsUsers} />
      <PrivateRoute path="/subgroup" exact component={SettingsSubGroup} />
      <PrivateRoute path="/profile" exact component={SettingsProfile} />
      <PrivateRoute path="/salle" exact component={InsuranceSale} />
      <PrivateRoute path="/search" exact component={InsuranceSearch} />
      <PrivateRoute path="/attendance" exact component={InsuranceAttendance} />
      {/* <PrivateRoute path="/devolution" exact={true} component={InsuranceDevolution} /> */}
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>
);

/* eslint-disable */
import IP from 'ip';
import Bowser from 'bowser';
import JWT from 'jsonwebtoken';

import { validatorPermissionsURI } from '..';

import { SECRET_KEY } from '../../constants';

/**
 * Function Authorize Token
 */
export const isAuthorizated = (props) => {
  const ip = IP.address();
  const browserParse = Bowser.getParser(window.navigator.userAgent);
  const browser = browserParse.getBrowserName();
  const uri = props.match.url.replace('/', '');
  const token = localStorage.getItem('token');

  return JWT.verify(token, SECRET_KEY, (err, decoded) => {
    if (err) {
      localStorage.removeItem('token');
      return false;
    }

    if (!(browser === decoded.data.browser) && ip === decoded.data.ip) {
      localStorage.removeItem('token');
      return false;
    }

    if (!validatorPermissionsURI(uri)) {
      localStorage.removeItem('token');
      return false;
    }

    return true;
  });
};

/**
 * Function Create Token
 * @param {obj} idToken
 */
export function createToken(idToken) {
  const ip = IP.address();
  const browserParse = Bowser.getParser(window.navigator.userAgent);
  const browser = browserParse.getBrowserName();
  const userInfo = JWT.decode(idToken);

  return JWT.sign(
    {
      data: {
        ip,
        browser,
        token: idToken,
        callcenterUserId: userInfo.id,
        userIdentity: userInfo.profile.sub_group.group.identify,
        name: userInfo.name,
      },
    },
    SECRET_KEY,
    { expiresIn: '3h' },
    (err, token) => {
      if (err) {
        return false;
      }

      localStorage.setItem('token', token);
    }
  );
}

/**
 * Function Validate Token
 * @param {string} type
 */
export function validateToken(type) {
  const token = localStorage.getItem('token');

  return JWT.verify(token, SECRET_KEY, (err, decoded) => {
    if (err) {
      return false;
    }

    switch (type) {
      case 'token':
        return decoded.data.token;
      case 'callcenterUserId':
        return decoded.data.callcenterUserId;
      case 'name':
        return decoded.data.name;
      case 'permissions':
        return decoded.data.permissions;
      case 'userIdentity':
        return decoded.data.userIdentity;
      default:
        return JWT.decode(decoded.data.token);
    }
  });
}

/**
 * Function Remove Token
 */
export function removeToken() {
  const token = localStorage.getItem('token');

  return JWT.verify(token, SECRET_KEY, (err) => {
    if (err) {
      return false;
    }
    localStorage.removeItem('token');
    return (window.location = '/');
  });
}

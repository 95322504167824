/* eslint-disable */
import React from 'react';
import IMAGE1 from './images/imagem.png';

export default (props) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-sm-8">
        <form className="form-horizontal" onSubmit={props.handleSubmit}>
          <div className="form-group">
            <label className="col-sm-2 control-label">Perfil *</label>
            <div className="col-sm-4">
              <select
                className="form-control"
                onChange={props.handleChange}
                name="profileId"
                value={props.profileId}
                required
              >
                <option value="">[Selecione uma opção]</option>
                {props.profileList.map((response, key) => (
                  <option key={key} value={response.id}>
                    {response.name.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Arquivo *</label>
            <div className="col-sm-4">
              <input
                name="file"
                accept=".csv"
                onChange={props.handleChangeFormUploadFile}
                type="file"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label" />
            <div className="col-sm-4">
              <div className="panel panel-warning">
                <div className="panel-heading">
                  <i className="fa fa-warning" /> Formato do Arquivo
                </div>
                <div className="panel-body">
                  <ul
                    style={{
                      fontWeight: 'bolder',
                      fontSize: 14,
                      paddingBottom: 10,
                    }}
                  >
                    <li>O arquivo deve ser de extensão .csv</li>
                    <li>
                      Deve ter duas colunas.
                      <ul style={{ fontWeight: 'normal' }}>
                        <li>
                          <span className="list_label">Primeira coluna: </span>
                          nome de usuário.
                        </li>
                        <li>
                          <span className="list_label">Segunda coluna: </span>
                          nome do atendente.
                        </li>
                      </ul>
                    </li>
                    <li>As colunas não dever ter títulos.</li>
                  </ul>
                  <img alt="" src={IMAGE1} className="img-responsive" />
                  <br />
                  ***{' '}
                  <a href="/assets/exemplo.csv" download>
                    Clique aqui
                  </a>{' '}
                  para baixar o arquivo de exemplo. ***
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line-dashed" />
          <div className="form-group">
            <div className="col-sm-4 col-sm-offset-2">
              <button
                className="btn btn-white"
                type="button"
                onClick={() => props.onClickReturnPage()}
              >
                Voltar
              </button>
              <button className="btn btn-primary" type="submit">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

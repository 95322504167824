const CONFIG = {
  system: 'insurance',
  page: 'salle',
  description: 'Seguros :: Vendas',
  title: 'Vendas',
};

const DISPLAY = {
  page: true,
  tabsSale: true,
  tabsSearchClient: false,
  formSearch: false,
  formSale: false,
  tabsClient: false,
  formConfirmSale: false,
  formSuccessSale: false,
  formConfirmRegister: false,
};

const FORM_DATA = {
  type: '',
  search: '',
  cpf: '',
  phone: '',
  userId: '',
  fullName: '',
  birthDate: '',
  msisdn: '',
  rg: '',
  email: '',
  gender: '',
  maritalStatus: '',
  cep: '',
  address: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
};

const FORM_SALE = {
  productName: '',
  productPrice: '',
  campaignId: '',
};

const COLUMNS_USERS = {
  fullName: 'NOME',
  cpf: 'CPF',
  phone: 'TELEFONE',
};

export { CONFIG, DISPLAY, FORM_DATA, FORM_SALE, COLUMNS_USERS };

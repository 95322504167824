/**
 * Function Validator Actions Protocol
 * @param {int} type
 */
export function validatorActionsProtocol(type) {
  const actions = [
    { key: 'SEARCH', name: 'CONSULTA' },
    { key: 'ATENDIMENTO', name: 'ATENDIMENTO' },
    { key: 'SUBSCRIPTION', name: 'ASSINATURA' },
    { key: 'UNSUBSCRIPTION', name: 'CANCELAMENTO' },
  ];

  const response = actions.find((action) => action.key === type && action);

  return response !== undefined && response.name;
}

/* eslint-disable */
import axios from 'axios';

import { validateToken } from '../../../utils';

import { URL_BACKEND_OPERATION } from '../../../constants';

const HEADERS = {
  'auth-jwt': `Bearer ${validateToken('token')}`,
};

/**
 * Function Get List All Profile
 */
export async function getListAllProfile() {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/profile/`,
      { headers: HEADERS }
    );
    return { status: true, rows: response.data };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Get List by Profile
 */
export async function getListProfile(queryString) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/profile/list?${queryString}&is_active=true`,
      { headers: HEADERS }
    );
    return {
      status: true,
      rows: response.data.data,
      totalRecord: response.data.paginate.total,
    };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Insert Profile
 */
export async function insertProfile(body) {
  try {
    await axios.post(`${URL_BACKEND_OPERATION}/callcenter/profile/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'Perfil inserido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Update Profile
 */
export async function updateProfile(body) {
  try {
    await axios.put(`${URL_BACKEND_OPERATION}/callcenter/profile/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'Perfil alterado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Delete Profile
 */
export async function deleteProfile(id) {
  try {
    await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/profile/${id}`, {
      headers: HEADERS,
    });
    return { status: true, message: 'Perfil removido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/* eslint-disable */
import axios from 'axios';
import moment from 'moment';

import { validatorActionsProtocol } from '../../../utils';

import {
  URL_BACKEND_OPERATION,
  KEY_BACKEND_OPERATION,
} from '../../../constants';

const HEADERS = {
  Authorization: KEY_BACKEND_OPERATION,
};

/**
 * Function Get Protocol Callcenter
 * @param {int} protocol
 */
export async function getProtocolCallcenter(protocol) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/protocol/user/${protocol}`,
      { headers: HEADERS }
    );
    const protocolUser = [];

    response.data.forEach((response) => {
      const createdAt =
        response.create_date !== null
          ? moment(response.create_date).format('DD/MM/YYYY HH:mm:ss')
          : '';
      const updatedAt = response.updated_at
        ? moment(response.updated_at).format('DD/MM/YYYY HH:mm:ss')
        : '';

      protocolUser.push({
        id: response.id,
        attendanceName: response.user.name,
        protocol: response.protocol,
        createdAt,
        updatedAt,
        action: validatorActionsProtocol(response.action),
        status: response.status === 'OPEN' ? 'ABERTO' : 'FECHADO',
      });
    });

    return { status: true, rows: protocolUser };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Send Protocol Callcenter
 * @param {json} body
 */
export async function sendProtocolCallcenter(body) {
  try {
    const response = await axios.post(
      `${URL_BACKEND_OPERATION}/callcenter/protocol/`,
      body,
      { headers: HEADERS }
    );
    return {
      status: true,
      dateProtocol: moment(response.data.create_date).format(
        'DD/MM/YYYY HH:mm:ss'
      ),
    };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Update Protocol Callcenter
 * @param {int} protocol
 * @param {body} body
 */
export async function updateProtocolCallcenter(protocol, body) {
  try {
    await axios.put(
      `${URL_BACKEND_OPERATION}/callcenter/protocol/${protocol}`,
      body,
      { headers: HEADERS }
    );
    return { status: true, message: 'Protocolo fechado com sucesso!' };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, não foi possivel fechar esse protocolo. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

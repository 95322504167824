/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import Layout from '../../../layout';
import Content from '../../../components/Content';
import Container from '../../../components/Container';
import Datatables from '../../../components/Datatables';
import TableInsuranceSale from '../../../components/Tables/Insurance/Sale';
import FormInsuranceSendSearch from '../../../components/Forms/Insurance/Sale/SendSearch';
import FormInsuranceSendSale from '../../../components/Forms/Insurance/Sale/SendSale';
import FormInsuranceConfirmSale from '../../../components/Forms/Insurance/Sale/ConfirmSale';
import FormInsuranceSuccessSale from '../../../components/Forms/Insurance/Sale/SuccessSale';

import {
  createProtocol,
  validateToken,
  validateProtocol,
  validatorPhoneNumber,
  validatorLandline,
  validatorCPF,
  toastrMessage,
} from '../../../utils';

import {
  sendProtocol,
  subscribeCallcenter,
  getSearchCallcenter,
} from '../../../services';

import {
  CONFIG,
  DISPLAY,
  FORM_DATA,
  FORM_SALE,
  COLUMNS_USERS,
} from './settings';

const Sale = () => {
  const [display, setDisplay] = useState(DISPLAY);
  const [formData, setFormData] = useState(FORM_DATA);
  const [formSale, setFormSale] = useState(FORM_SALE);
  const [progressBar, setProgressBar] = useState(false);
  const [userData, setUserData] = useState([]);

  /**
   * Function On Click Button Form Send Sale
   * @param {event} e
   */
  const onClickButtonSelectProduct = (e, product) => {
    e.preventDefault();
    setProgressBar(true);

    setTimeout(() => {
      setProgressBar(false);

      CONFIG.title = `Iniciar Venda - Produto: ${product.name} - ${product.price}`;

      setFormSale({
        ...formSale,
        productName: product.name,
        productPrice: product.price,
        campaignId: product.id,
      });

      setDisplay({
        ...display,
        tabsSale: false,
        formSearch: true,
      });
    }, 1000);
  };

  /**
   * Function Handle Change Form
   * @param {event} e
   */
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Function Handle Submit Form Send Search
   * @param {event} e
   */
  const handleSubmitFormSendSearch = async (e) => {
    e.preventDefault();
    setProgressBar(true);

    setDisplay({
      ...display,
      tabsClient: false,
    });

    if (formData.type === 'phone') {
      const validator = validatorLandline(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.landline;
    }

    if (formData.type === 'cpf') {
      const validator = validatorCPF(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.cpf;
    }

    const response = await getSearchCallcenter(
      formData.type,
      parseInt(formData.search, 10)
    );

    if (!response.status) {
      toastrMessage('error', response.message);
      setProgressBar(false);
      return;
    }

    if (response.users.length === 0) {
      setProgressBar(false);

      setFormData({
        ...formData,
        cpf: formData.type === 'cpf' ? formData.search : '',
        phone: formData.type === 'phone' ? formData.search : '',
      });

      setDisplay({
        ...display,
        formSale: true,
        tabsClient: false,
        formSearch: false,
      });

      return;
    }

    setProgressBar(false);

    setDisplay({
      ...display,
      tabsClient: true,
    });

    setUserData(response.users);

    setFormData({
      ...formData,
      cpf: formData.type === 'cpf' ? formData.search : '',
      phone: formData.type === 'phone' ? formData.search : '',
    });
  };

  /**
   * Function On Click Button Form Confirm Sale
   * @param {event} e
   */
  const onClickButtonHistoryOrSale = (e, record) => {
    e.preventDefault();
    setProgressBar(true);

    setTimeout(() => {
      setProgressBar(false);

      setFormData({
        ...formData,
        fullName: record.fullName,
        birthDate: record.birthDate,
        msisdn: record.msisdn,
        phone: record.phone,
        cpf: record.cpf,
        rg: record.rg,
        email: record.email,
        gender: record.gender,
        maritalStatus: record.maritalStatus,
        cep: record.cep,
        address: record.address,
        number: record.number,
        complement: record.complement,
        neighborhood: record.neighborhood,
        city: record.city,
        state: record.state,
      });

      setDisplay({
        ...display,
        formSearch: false,
        tabsClient: false,
        formSale: true,
      });
    }, 1000);
  };

  /**
   * Function Handle Submit Form Register Client
   * @param {event} e
   */
  const handleSubmitFormRegisterClient = async (e) => {
    e.preventDefault();

    const validPhoneNumber = validatorPhoneNumber(formData.msisdn);

    if (!validPhoneNumber.status) {
      setProgressBar(false);
      toastrMessage('error', validPhoneNumber.message);
      return;
    }

    const validLandline = validatorLandline(formData.phone);

    if (!validLandline.status) {
      setProgressBar(false);
      toastrMessage('error', validLandline.message);
      return;
    }

    const validCPF = validatorCPF(formData.cpf);

    if (!validCPF.status) {
      setProgressBar(false);
      toastrMessage('error', validCPF.message);
      return;
    }

    setProgressBar(true);

    setTimeout(() => {
      setProgressBar(false);

      setFormData({
        ...formData,
        msisdn: validPhoneNumber.msisdn,
        phone: validLandline.landline,
        cpf: validCPF.cpf,
      });

      setDisplay({
        ...display,
        formConfirmSale: true,
        formConfirmRegister: true,
        formSale: false,
      });
    }, 1000);
  };

  /**
   * Function On Click Button Subscription
   * @param {event} e
   */
  const onClickButtonSubscription = async (e) => {
    e.preventDefault();

    if (window.confirm('Você realmente deseja confirmar esta venda?')) {
      setProgressBar(true);

      const sendProtocolResponse = await sendProtocol({
        identify: validateToken('userIdentity'),
        event: 'CALL_CENTER',
      });

      if (!sendProtocolResponse.status) {
        setProgressBar(false);
        toastrMessage('error', sendProtocolResponse.message);
        return;
      }

      const subscribeCallcenterResponse = await subscribeCallcenter(
        validateToken('userIdentity'),
        validateToken('callcenterUserId'),
        formSale.campaignId,
        formData.fullName,
        formData.birthDate,
        formData.phone,
        formData.msisdn,
        formData.cpf,
        formData.email,
        formData.gender,
        formData.rg,
        sendProtocolResponse.protocol,
        formData.maritalStatus,
        formData.complement,
        formData.number,
        formData.cep,
        formData.address,
        formData.neighborhood,
        formData.city,
        formData.state
      );

      if (subscribeCallcenterResponse.status === 'info') {
        toastrMessage('warning', subscribeCallcenterResponse.message);
        setProgressBar(false);
        return;
      }

      if (!subscribeCallcenterResponse.status) {
        toastrMessage('error', subscribeCallcenterResponse.message);
        setProgressBar(false);
        return;
      }

      setProgressBar(true);

      createProtocol({
        phone: formData.phone,
        fullName: formData.fullName,
        cpf: formData.cpf,
        email: formData.email,
        protocol: sendProtocolResponse.protocol,
        dateProtocol: subscribeCallcenterResponse.dateProtocol,
      });

      setTimeout(() => {
        setProgressBar(false);

        setDisplay({
          ...display,
          formConfirmSale: false,
          formSuccessSale: true,
        });
      }, 1500);
    }
  };

  /**
   * Function On Click Back Form Insurance Send Search
   */
  const onClickBackFormInsuranceSendSearch = () => {
    setFormData(FORM_DATA);
    setDisplay({
      ...display,
      tabsSale: true,
      tabsClient: false,
      formSearch: false,
    });
  };

  /**
   * Function On Click Back Form Insurance Send Sale
   */
  const onClickBackFormInsuranceSendSale = () => {
    setFormData(FORM_DATA);
    setDisplay({
      ...display,
      formSale: false,
      formSearch: true,
    });
  };

  /**
   * Redirect Page Search
   */
  const redirectPage = () => {
    window.location = '/search';
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    if (validateProtocol()) {
      setProgressBar(true);
      setDisplay({
        ...display,
        page: false,
      });
      redirectPage();
    }
  }, []);

  return (
    <Layout
      system={CONFIG.system}
      page={CONFIG.page}
      description={CONFIG.description}
      progressBar={progressBar}
    >
      {display.page && (
        <Content title={CONFIG.title}>
          {display.tabsSale && (
            <TableInsuranceSale
              onClickButtonSelectProduct={(e, product) =>
                onClickButtonSelectProduct(e, product)
              }
            />
          )}

          {display.formSearch && (
            <FormInsuranceSendSearch
              page={CONFIG.page}
              type={formData.type}
              search={formData.search}
              handleChange={(e) => handleChange(e)}
              handleSubmit={(e) => handleSubmitFormSendSearch(e)}
              onClickButtonBackForm={() => onClickBackFormInsuranceSendSearch()}
            />
          )}

          {display.tabsClient && (
            <Container title="Dados do Cliente *">
              <Datatables
                columns={COLUMNS_USERS}
                permissions={CONFIG.page}
                page={CONFIG.page}
                rows={userData}
                btnAddDefault={false}
                btnDeleteDefault={false}
                btnSubscription
                onClickButtonHistoryOrSale={(e, record) =>
                  onClickButtonHistoryOrSale(e, record)
                }
              />
            </Container>
          )}

          {display.formSale && (
            <FormInsuranceSendSale
              fullName={formData.fullName}
              msisdn={formData.msisdn}
              phone={formData.phone}
              cpf={formData.cpf}
              rg={formData.rg}
              email={formData.email}
              birthDate={formData.birthDate}
              gender={formData.gender}
              maritalStatus={formData.maritalStatus}
              cep={formData.cep}
              address={formData.address}
              number={formData.number}
              complement={formData.complement}
              neighborhood={formData.neighborhood}
              city={formData.city}
              state={formData.state}
              onClickButtonBackSendSale={() =>
                onClickBackFormInsuranceSendSale()
              }
              handleChange={(e) => handleChange(e)}
              handleSubmit={(e) => handleSubmitFormRegisterClient(e)}
            />
          )}

          {display.formConfirmSale && (
            <FormInsuranceConfirmSale
              cpf={formData.cpf}
              phone={formData.phone}
              fullName={formData.fullName}
              productName={formSale.productName}
              productPrice={formSale.productPrice}
              displayFormConfirmRegister
              onClickButtonBack={() =>
                setDisplay({
                  ...display,
                  formSale: true,
                  formConfirmSale: false,
                })
              }
              onClickButtonConfirmSaleProduct={(e) =>
                onClickButtonSubscription(e)
              }
            />
          )}

          {display.formSuccessSale && (
            <FormInsuranceSuccessSale
              productName={formSale.productName}
              onClickButtonBackSaleProduct={() => redirectPage()}
            />
          )}
        </Content>
      )}
    </Layout>
  );
};

export default Sale;

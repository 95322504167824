/**
 * Function URL Query String
 * @param {obj} obj
 */
export function urlQueryString(obj) {
  const query = Object.keys(obj)
    .map((key) => {
      switch (key) {
        case 'page_number':
          return `${encodeURIComponent('page')}=${encodeURIComponent(
            obj[key]
          )}`;
        case 'page_size':
          return `${encodeURIComponent('limit')}=${encodeURIComponent(
            obj[key]
          )}`;
        case 'sort_order':
          return obj[key].length > 0
            ? `${encodeURIComponent('sort')}=${encodeURIComponent(
                obj[key].order
              )}`
            : '';
        case 'filter_value':
          return obj[key].length > 0
            ? `${encodeURIComponent('protocol')}=${encodeURIComponent(
                obj[key]
              )}`
            : '';
        default:
          return '';
      }
    })
    .filter((value) => !!value)
    .join('&');

  return query;
}

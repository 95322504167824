/* eslint-disable */
import React from 'react';
import PasswordMask from 'react-password-mask';
import FlashMessage from '../../FlashMessage';

export default (props) => (
  <form onSubmit={props.handleSubmit} className="m-t">
    <div className="form-group">
      <input
        type="text"
        name="username"
        value={props.username}
        onChange={props.handleChange}
        placeholder="Usuário"
        className="form-control"
        required
      />
    </div>
    <div className="form-group">
      <PasswordMask
        name="password"
        id="password"
        placeholder="Senha"
        inputClassName="form-control"
        value={props.password}
        onChange={props.handleChange}
        showButtonContent="Exibir"
        hideButtonContent="Ocultar"
        required
      />
    </div>
    <FlashMessage
      statusFlashMessage={props.statusFlashMessage}
      flashMessage={props.flashMessage}
    />
    <button
      type="submit"
      className="btn btn-info btn-login block full-width m-b"
    >
      Entrar
    </button>
  </form>
);

/* eslint-disable no-alert */
import React, { useEffect, useState, useCallback } from 'react';
import queryString from 'query-string';
import Layout from '../../../layout';
import Content from '../../../components/Content';
import Datatables from '../../../components/Datatables';
import FormAttendance from '../../../components/Forms/Insurance/Attendance';
import FormInsuranceSendSearch from '../../../components/Forms/Insurance/Sale/SendSearch';

import {
  getListAttendance,
  getListAttendanceById,
  insertAttendance,
  updateAttendance,
  deleteAttendance,
  sendProtocol,
  getSearchCallcenter,
  getTypeAttendance,
} from '../../../services';

import {
  validateToken,
  validateProtocol,
  toastrMessage,
  validatorEmail,
  validatorLandline,
  validatorCPF,
  urlQueryString,
} from '../../../utils';

import { CONFIG, DISPLAY, COLUMNS, FORM_DATA } from './settings';

const Attendance = () => {
  const [display, setDisplay] = useState(DISPLAY);
  const [formData, setFormData] = useState(FORM_DATA);
  const [loader, setLoader] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const [typesAttendance, setTypesAttendance] = useState([]);
  const [actions, setActions] = useState('');
  const [searchAttendanceId, setSearchAttendanceId] = useState(false);

  /**
   * Function Get List
   */
  const getData = useCallback(async (query = 'page=1&limit=10') => {
    setLoader(true);
    setProgressBar(true);

    setDisplay({
      ...display,
      list: true,
      form: false,
    });

    const listAttendanceResponse = await getListAttendance(query);

    if (!listAttendanceResponse.status) {
      setLoader(false);
      setProgressBar(false);
      toastrMessage('error', listAttendanceResponse.message);
      return;
    }

    const typeAttendanceResponse = await getTypeAttendance();

    if (!typeAttendanceResponse.status) {
      setLoader(false);
      setProgressBar(false);
      toastrMessage('error', typeAttendanceResponse.message);
      return;
    }

    listAttendanceResponse.rows.forEach((attendance, i) => {
      typeAttendanceResponse.data.forEach((type) => {
        if (parseInt(attendance.typeId, 10) === type.id) {
          listAttendanceResponse.rows[i].typeName = type.name;
        }
      });
    });

    const typeAttendance = typeAttendanceResponse.data.filter(
      (obj) => obj.show_selectbox === '1'
    );

    setLoader(false);
    setProgressBar(false);
    setTypesAttendance(typeAttendance);
    setData(listAttendanceResponse.rows);
    setTotalData(listAttendanceResponse.totalRecord);
  });

  /**
   * Function On Click Button Insert
   */
  const onClickButtonInsert = () => {
    setActions('insert');
    setDisplay({
      ...display,
      list: false,
      search: true,
    });
  };

  /**
   * Function on Click Button Edit
   * @param {object} obj
   */
  const onClickButtonEdit = (obj) => {
    setActions('edit');
    setDisplay({
      ...display,
      list: false,
      form: true,
    });

    setFormData({
      ...formData,
      attendanceId: obj.id,
      protocol: obj.protocol,
      date: obj.date,
      name: obj.name,
      cpf: obj.cpf,
      phone: obj.phone,
      email: obj.email,
      channelName: obj.channelName,
      typeId: obj.typeId,
      comment: obj.comment,
      statusId: obj.statusId,
      statusName: obj.statusName,
      disabled: true,
    });
  };

  /**
   * Function on Click Button Delete
   * @param {object} obj
   */
  const onClickButtonDelete = async (obj) => {
    if (window.confirm('Você deseja excluir esse registro?')) {
      setProgressBar(true);
      const response = await deleteAttendance(obj.id);
      if (!response.status) {
        setProgressBar(false);
        toastrMessage('error', response.message);
        return;
      }
      getData();
      toastrMessage('success', response.message);
    }
  };

  /**
   * Function Handle Change Form
   * @param {event} e
   */
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Function Handle Submit Form Send Search
   * @param {event} e
   */
  const handleSubmitFormSendSearch = async (e) => {
    e.preventDefault();
    setProgressBar(true);

    if (formData.type === 'phone') {
      const validator = validatorLandline(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.landline;
    }

    if (formData.type === 'cpf') {
      const validator = validatorCPF(formData.search);
      if (!validator.status) {
        setProgressBar(false);
        toastrMessage('error', validator.message);
        return;
      }
      formData.search = validator.cpf;
    }

    const response = await getSearchCallcenter(
      formData.type,
      parseInt(formData.search, 10)
    );

    if (!response.status) {
      toastrMessage('error', response.message);
      setProgressBar(false);
      return;
    }

    setProgressBar(false);

    setDisplay({
      ...display,
      search: false,
      form: true,
    });

    let name = '';
    let email = '';
    let cpf = formData.type === 'cpf' && formData.search;
    let phone = formData.type === 'phone' && formData.search;
    let externalClientId = null;

    if (response.users.length > 0) {
      name = response.users[0].fullName;
      email = response.users[0].email;
      cpf = response.users[0].cpf;
      phone = response.users[0].phone;
      externalClientId = response.users[0].id;
    }

    setFormData({
      ...formData,
      name,
      email,
      cpf,
      phone,
      externalClientId,
    });
  };

  /**
   * Function Insert
   */
  const insert = async () => {
    const validEmail = validatorEmail(formData.email);

    if (!validEmail.status) {
      toastrMessage('error', validEmail.message);
      setProgressBar(false);
      return;
    }

    const validLandline = validatorLandline(formData.phone);

    if (!validLandline.status) {
      toastrMessage('error', validLandline.message);
      setProgressBar(false);
      return;
    }

    const validCPF = validatorCPF(formData.cpf);

    if (!validCPF.status) {
      toastrMessage('error', validCPF.message);
      setProgressBar(false);
      return;
    }

    setProgressBar(true);

    const sendProtocolResponse = await sendProtocol({
      identify: validateToken('userIdentity'),
      event: 'CALL_CENTER',
    });

    if (!sendProtocolResponse.status) {
      toastrMessage('error', sendProtocolResponse.message);
      setProgressBar(false);
      return;
    }

    const insertAttendanceResponse = await insertAttendance({
      name: formData.name,
      email: validEmail.email,
      cpf: parseInt(validCPF.cpf, 10),
      phone: parseInt(`55${validLandline.landline}`, 10),
      attendance_type_id: parseInt(formData.typeId, 10),
      callcenter_user_id: parseInt(validateToken('callcenterUserId'), 10),
      protocol: sendProtocolResponse.protocol,
      external_client_id: formData.externalClientId,
      user_id: null,
      status: 1,
      comment: formData.comment,
      channel: formData.channelName,
    });

    if (!insertAttendanceResponse.status) {
      setProgressBar(false);
      toastrMessage('error', insertAttendanceResponse.message);
      return;
    }

    toastrMessage('success', insertAttendanceResponse.message);

    setTimeout(() => {
      if (searchAttendanceId) {
        window.location.href = '/search';
        return;
      }
      setFormData(FORM_DATA);
      getData();
    }, 1500);
  };

  /**
   * Function Update
   */
  const update = async () => {
    setProgressBar(true);

    const response = await updateAttendance({
      attendance_id: parseInt(formData.attendanceId, 10),
      callcenter_user_id: parseInt(validateToken('callcenterUserId'), 10),
      answer: formData.answer,
      status: parseInt(formData.statusId, 10),
    });

    if (!response.status) {
      setProgressBar(false);
      toastrMessage('error', response.message);
      return;
    }

    toastrMessage('success', response.message);

    setTimeout(() => {
      if (searchAttendanceId) {
        window.location.href = '/search';
        return;
      }
      getData();
    }, 1500);
  };

  /**
   * Handle Submit Form
   * @param {event} e
   */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (actions === 'edit') {
      update();
      return;
    }
    insert();
  };

  /**
   * Function Get List Data By Id
   */
  const listAttendanceById = async (id) => {
    setProgressBar(true);
    setDisplay({
      ...display,
      page: false,
      list: false,
    });

    const response = await getListAttendanceById(id);

    if (!response.status) {
      setProgressBar(false);
      toastrMessage('error', response.message);
      return;
    }

    setProgressBar(false);
    setSearchAttendanceId(true);
    setActions('edit');
    setDisplay({
      ...display,
      page: true,
      list: false,
      form: true,
    });

    setFormData({
      ...formData,
      attendanceId: response.rows[0].id,
      protocol: response.rows[0].protocol,
      date: response.rows[0].date,
      name: response.rows[0].name,
      cpf: response.rows[0].cpf,
      phone: response.rows[0].phone,
      email: response.rows[0].email,
      channelName: response.rows[0].channelName,
      typeId: response.rows[0].typeId,
      comment: response.rows[0].comment,
      statusId: response.rows[0].statusId,
      statusName: response.rows[0].statusName,
      externalClientId: response.rows[0].id,
      disabled: true,
    });
  };

  /**
   * Function Get List Data By Phone
   */
  const listDataByPhone = async (phone) => {
    setProgressBar(true);

    setDisplay({
      ...display,
      page: false,
      list: false,
    });

    const response = await getSearchCallcenter('phone', phone);

    if (!response.status) {
      setProgressBar(false);
      toastrMessage('error', response.message);
      return;
    }

    if (response.users.length === 0) {
      setProgressBar(true);
      setProgressBar(false);
      setSearchAttendanceId(true);
      setActions('insert');

      setDisplay({
        ...display,
        list: false,
        page: true,
        form: true,
      });

      return;
    }

    setProgressBar(false);

    setDisplay({
      ...display,
      list: false,
      form: true,
    });

    setSearchAttendanceId(true);
    setActions('insert');

    setFormData({
      ...formData,
      date: response.users[0].date,
      name: response.users[0].fullName,
      cpf: response.users[0].cpf,
      phone: response.users[0].phone,
      email: response.users[0].email,
      externalClientId: response.users[0].id,
      disabled: false,
    });
  };

  /**
   * Function List Attendance Type
   */
  const listAttendanceType = useCallback(async () => {
    const response = await getTypeAttendance();

    if (!response.status) {
      toastrMessage('error', response.message);
      return;
    }

    const typeAttendance = response.data.filter(
      (obj) => obj.show_selectbox === '1'
    );

    setTypesAttendance(typeAttendance);
  });

  /**
   * Function on Click Button Back Form
   */
  const onClickButtonBackForm = () => {
    if (searchAttendanceId) {
      window.location.href = '/search';
      return;
    }

    setFormData(FORM_DATA);
    setDisplay({
      ...display,
      list: true,
      search: false,
    });
  };

  /**
   * Function on Click Button Back Form Attendance
   */
  const onClickButtonBackFormAttendance = () => {
    if (searchAttendanceId) {
      window.location.href = '/search';
      return;
    }

    setFormData(FORM_DATA);
    setDisplay({
      ...display,
      form: false,
      search: true,
    });
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    if (validateProtocol()) {
      listAttendanceType();
      setProgressBar(true);
      setDisplay({
        ...display,
        page: false,
      });

      const url = queryString.parse(window.location.search);

      if (url.a) {
        listDataByPhone(validateProtocol('phone'));
        return;
      }

      if (url.e) {
        listAttendanceById(url.e);
        return;
      }

      window.location.href = '/search';
      return;
    }

    getData();
    listAttendanceType();
  }, []);

  return (
    <Layout
      page={CONFIG.page}
      system={CONFIG.system}
      description={CONFIG.description}
      progressBar={progressBar}
    >
      {display.page && (
        <Content title={CONFIG.title}>
          {display.list && (
            <Datatables
              dynamic
              filter
              btnAddDefault
              btnEditDefault
              btnDeleteDefault
              permissions={CONFIG.page}
              columns={COLUMNS}
              loading={loader}
              rows={data}
              totalRecord={totalData}
              insertRecord={(e) => onClickButtonInsert(e)}
              editRecord={(e) => onClickButtonEdit(e)}
              deleteRecord={(e) => onClickButtonDelete(e)}
              onChange={(e) => getData(urlQueryString(e))}
            />
          )}

          {display.search && (
            <FormInsuranceSendSearch
              page={CONFIG.page}
              type={formData.type}
              search={formData.search}
              onClickButtonBackForm={(e) => onClickButtonBackForm(e)}
              handleChange={(e) => handleChange(e)}
              handleSubmit={(e) => handleSubmitFormSendSearch(e)}
            />
          )}

          {display.form && (
            <FormAttendance
              action={actions}
              disabled={formData.disabled}
              required={formData.required}
              protocol={formData.protocol}
              date={formData.date}
              name={formData.name}
              cpf={formData.cpf}
              phone={formData.phone}
              email={formData.email}
              channelName={formData.channelName}
              typeId={formData.typeId}
              comment={formData.comment}
              answer={formData.answer}
              statusId={formData.statusId}
              typesAttendance={typesAttendance}
              handleChange={(e) => handleChange(e)}
              handleSubmit={(e) => handleSubmitForm(e)}
              onClickReturnPage={() => onClickButtonBackFormAttendance()}
            />
          )}
        </Content>
      )}
    </Layout>
  );
};

export default Attendance;

/* eslint-disable */
import React from 'react';
import './styles.css';

export default (props) => (
  <div
    className="loading"
    id="loading"
    style={{ display: `${props.display ? 'block' : 'none'}` }}
  >
    <div className="loader" />
  </div>
);

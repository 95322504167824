/* eslint-disable */
import React from 'react';

export default () => (
  <div className="footer">
    <div className="pull-right">
      <strong>Copyright FS</strong>© 2020
    </div>
  </div>
);

/* eslint-disable */
import React from 'react';
// import Dashboard from '../Systems/Dashboard'
import Settings from '../Systems/Settings';
import Insurance from '../Systems/Insurance';

export default (props) => (
  <nav
    className="navbar-default navbar-static-side fullheight"
    role="navigation"
  >
    <div className="sidebar-collapse">
      <ul className="nav metismenu" id="side-menu">
        <li className="nav-header text-center">
          <div className="dropdown profile-element">
            <span>
              <img alt="" src="/assets/images/logo.png" height="110px" />
            </span>
          </div>
          <div className="logo-element" style={{ background: '#f3f3f4' }}>
            <img alt="" src="/assets/images/logo-2.png" />
          </div>
        </li>
        {/* <Dashboard {...props} /> */}
        <Settings {...props} />
        <Insurance {...props} />
      </ul>
    </div>
  </nav>
);

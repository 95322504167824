/* eslint-disable */
import React, { Component } from 'react';
import JWT from 'jsonwebtoken';
import FlashMessage from '../FlashMessage';

import { updateProtocolCallcenter } from '../../services';

export default class Protocol extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      statusMessage: false,
      statusFlashMessage: '',
      flashMessage: '',
      protocol: '',
      dateProtocol: '',
      protocolStatus: false,
    };

    this.onClickButtonHistoryProduct = this.onClickButtonHistoryProduct.bind(
      this
    );
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    if (
      localStorage.hasOwnProperty('protocol') &&
      localStorage.getItem('protocol') !== ''
    ) {
      const protocol = localStorage.getItem('protocol');
      const token = JWT.decode(protocol);
      this.setState({
        protocolStatus: true,
        protocol: token.data.protocol,
        dateProtocol: token.data.dateProtocol,
        fullName: token.data.fullName,
      });
    }
  }

  /**
   * Function On Click Button History Product
   * @param {event} e
   */
  onClickButtonHistoryProduct = (e) => {
    e.preventDefault();
    window.location = '/search';
  };

  /**
   * Function on Click Button Protocol
   * @param {event} e
   * @param {int} protocol
   */
  async onClickButtonCancelProtocol(e, protocol) {
    e.preventDefault();

    if (window.confirm('Voce deseja fechar esse protocolo?')) {
      document.getElementById('loading').style.display = 'block';

      this.setState({
        statusFlashMessage: '',
        flashMessage: '',
      });

      const response = await updateProtocolCallcenter(protocol, {
        status: 'CLOSE',
      });

      if (!response.status) {
        document.getElementById('loading').style.display = 'none';
        this.setState({
          statusMessage: true,
          statusFlashMessage: true,
          flashMessage: response.message,
        });
        return;
      }

      this.setState({
        protocolStatus: false,
        protocol: '',
        dateProtocol: '',
        fullName: '',
      });

      setTimeout(() => {
        alert(response.message);
        document.getElementById('loading').style.display = 'none';
        localStorage.removeItem('protocol');
        window.location = window.location.href;
      }, 1000);
    }
  }

  /**
   * Render Component
   */
  render() {
    const {
      statusMessage,
      statusFlashMessage,
      flashMessage,
      protocol,
      dateProtocol,
      fullName,
      protocolStatus,
    } = this.state;

    if (this.props.protocolStatus) {
      return (
        <div className="alert alert-info alert-dismissable">
          Protocolo número: <strong>{this.props.protocol}</strong> criado em{' '}
          <strong>{this.props.dateProtocol}</strong> referente ao cliente:{' '}
          <strong>{this.props.fullName}</strong>
          <button
            type="button"
            className="btn btn-w-m btn-success"
            style={{ marginLeft: 10 }}
            onClick={this.onClickButtonHistoryProduct}
          >
            <i className="fa fa-search" /> Histórico
          </button>
          <button
            type="button"
            className="btn btn-w-m btn-danger"
            style={{ marginLeft: 10 }}
            onClick={(e) =>
              this.onClickButtonCancelProtocol(e, this.props.protocol)
            }
          >
            <i className="fa fa-close" /> Fechar Atendimento
          </button>
        </div>
      );
    }

    return (
      <>
        {protocolStatus ? (
          <>
            <div className="alert alert-info alert-dismissable">
              Protocolo número: <strong>{protocol}</strong> criado em{' '}
              <strong>{dateProtocol}</strong> referente ao cliente:{' '}
              <strong>{fullName}</strong>
              <button
                type="button"
                className="btn btn-w-m btn-success"
                style={{ marginLeft: 10 }}
                onClick={this.onClickButtonHistoryProduct}
              >
                <i className="fa fa-search" /> Histórico
              </button>
              <button
                type="button"
                className="btn btn-w-m btn-danger"
                style={{ marginLeft: 10 }}
                onClick={(e) => this.onClickButtonCancelProtocol(e, protocol)}
              >
                <i className="fa fa-close" /> Fechar Atendimento
              </button>
            </div>
          </>
        ) : (
          ''
        )}

        {statusMessage ? (
          <>
            <FlashMessage
              statusFlashMessage={statusFlashMessage}
              flashMessage={flashMessage}
            />
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

/* eslint-disable */
import React from 'react';
import { PRODUCT_HOME_INSURANCE } from '../../../../../constants';

import { validatorPermissions } from '../../../../../utils';

export default (props) => {
  const products = PRODUCT_HOME_INSURANCE;

  /**
   * Function Create Buttons Table
   */

  const buttons = [];

  products.forEach((obj, key) => {
    buttons.push(
      <td key={key}>
        <button
          type="button"
          className="btn btn-info"
          onClick={(e) => props.onClickButtonSelectProduct(e, obj)}
        >
          <i className="fa fa-lock" /> Vender
        </button>
      </td>
    );
  });

  return (
    <div className="table-responsive">
      <table
        className="table"
        cellPadding={4}
        cellSpacing={2}
        width="100%"
        style={{
          borderSpacing: 2,
          borderCollapse: 'separate',
          fontSize: 14,
          textAlign: 'center',
        }}
      >
        <tbody>
          <tr style={{ backgroundColor: '#dedede', color: '#666666' }}>
            <td align="left">
              <b>COBERTURA</b>
            </td>
            <td>
              <b>Plano 1</b>
            </td>
            <td>
              <b>Plano 2</b>
            </td>
            <td>
              <b>Plano 3</b>
            </td>
            <td>
              <b>Plano 4</b>
            </td>
            <td>
              <b>Plano 5</b>
            </td>
            <td>
              <b>Plano 6</b>
            </td>
            <td>
              <b>Plano 7</b>
            </td>
            <td>
              <b>Franquia</b>
            </td>
          </tr>
          <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
            <td
              align="left"
              rel="popover"
              data-title="<b>Incêndio. Raio, Explosão e Queda de Aeronaves</b>"
              data-content="A cobertura básica protege seu patrimônio em caso de danos materiais causados por: incêndios e explosões de qualquer natureza, inclusive quando decorrentes de tumultos; queda de raio ocorrido na residência ou terreno segurado, e de aeronaves. Exemplo: Acidente com panela de pressão"
              data-original-title
            >
              <i className="fa fa-question-circle" /> Incêndio. Raio, Explosão e
              Queda de Aeronaves
            </td>
            <td>R$ 25.000,00</td>
            <td>R$ 25.000,00</td>
            <td>R$ 30.000,00</td>
            <td>R$ 30.000,00</td>
            <td>R$ 30.000,00</td>
            <td>R$ 35.000,00</td>
            <td>R$ 40.000,00</td>
            <td>10% (Mínimo de R$ 150,00)</td>
          </tr>
          <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
            <td
              align="left"
              rel="popover"
              data-title="<b>Roubo e Furto de Bens Mediante Arrombamento</b>"
              data-content="Protege seu patrimônio contra roubo e furto por arrombamento; reembolsa os bens levados do interior do imóvel; arca com os estragos causados pela invasão em: portas, janelas e fechaduras."
              data-original-title
            >
              <i className="fa fa-question-circle" /> Roubo e Furto de Bens
              Mediante Arrombamento
            </td>
            <td>R$ 500,00</td>
            <td>Não</td>
            <td>R$ 1.000,00</td>
            <td>Não</td>
            <td>R$ 1.000,00</td>
            <td>R$ 1.250,00</td>
            <td>R$ 1.500,00</td>
            <td>10% (Mínimo de R$ 150,00)</td>
          </tr>
          <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
            <td
              align="left"
              rel="popover"
              data-title="<b>Danos Elétricos</b>"
              data-content="garante os danos materiais causados a equipamentos e instalações eletroeletrônicas decorrentes de: curto-circuito; descargas elétricas; variações de tensão, energia; outros problemas provocados por evento externo. <b>Exemplo:</b> queda de raio fora do terreno segurado."
              data-original-title
            >
              <i className="fa fa-question-circle" /> Danos Elétricos
            </td>
            <td>R$ 1.000,00</td>
            <td>R$ 1.000,00</td>
            <td>R$ 1.000,00</td>
            <td>R$ 1.000,00</td>
            <td>R$ 1.000,00</td>
            <td>R$ 5.000,00</td>
            <td>R$ 7.500,00</td>
            <td>10% (Mínimo de R$ 150,00)</td>
          </tr>
          <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
            <td
              align="left"
              rel="popover"
              data-title="<b>Vendavais - Fenômenos Naturais</b>"
              data-content="A cobertura assegura o reembolso dos bens danificados em consequência:<br/>-Do mau tempo. Ex.: furacões; ciclones; tornados; chuva de granizo; ou ventos fortes, consequentes de vendavais;<br/>-Das perdas causadas pela água da chuva e pelo impacto de veículos terrestres, consequentes do vendaval ou granizo."
              data-original-title
            >
              <i className="fa fa-question-circle" /> Vendaval - Fenômenos
              Naturais
            </td>
            <td>Não</td>
            <td>Não</td>
            <td>Não</td>
            <td>Não</td>
            <td>Não</td>
            <td>R$ 1.250,00</td>
            <td>R$ 1.500,00</td>
            <td />
          </tr>
          <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
            <td
              align="left"
              rel="popover"
              data-title="<b>Assistência Personalizado</b>"
              data-content="Serviços a residência segurada. <b>Em caso de emergência:</b> Segurança e Vigilância, Cobertura provisória de telhados e indicação de profissionais. <b>Não Emergenciais:</b> Revisão na instalação elétrica; hidráulica; Lubrificação de Fechaduras e dobradiças; Substituição de telhas; Limpeza de caixa d’água e calhas; Caça Vazamentos."
              data-original-title
            >
              <i className="fa fa-question-circle" /> Assistência Personalizado
            </td>
            <td>Não</td>
            <td>Sim</td>
            <td>Não</td>
            <td>Sim</td>
            <td>Sim</td>
            <td>Sim</td>
            <td>Sim</td>
            <td> 10%&nbsp; com Mínimo de R$ 150,00</td>
          </tr>
          <tr style={{ backgroundColor: '#dedede', color: '#666666' }}>
            <td align="left">
              <b>PRÊMIO MENSAL - MENSALIDADE</b>
            </td>
            <td>
              <b>R$ 6,90</b>
            </td>
            <td>
              <b>R$ 6,99</b>
            </td>
            <td>
              <b>R$ 9,90</b>
            </td>
            <td>
              <b>R$ 9,99</b>
            </td>
            <td>
              <b>R$ 14,90</b>
            </td>
            <td>
              <b>R$ 18,90</b>
            </td>
            <td>
              <b>R$ 21,90</b>
            </td>
            <td />
          </tr>
          <tr style={{ color: '#FF3333', fontSize: 18 }}>
            <td align="left" />
            {validatorPermissions('salle').has('create') && buttons}
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

/* eslint-disable */
import axios from 'axios';
import moment from 'moment';

import { validateToken, validatorTypeAttendance } from '../../../utils';

import { URL_BACKEND_OPERATION } from '../../../constants';

const HEADERS = {
  'auth-jwt': `Bearer ${validateToken('token')}`,
};

/**
 * Function Get List Attendance
 */
export async function getListAttendance(queryString) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/attendance/list?${queryString}&is_active=true`,
      { headers: HEADERS }
    );
    const attendance = [];

    response.data.data.forEach((response) => {
      const cpf = response.attendance_client_cpf
        ? response.attendance_client_cpf.toString().length < 11
          ? response.attendance_client_cpf.toString().padStart(11, 0)
          : response.attendance_client_cpf.toString()
        : '';

      const phone = response.attendance_client_phone
        ? response.attendance_client_phone.toString().length > 10
          ? response.attendance_client_phone.toString().substr(2)
          : response.attendance_client_phone.toString()
        : '';

      const createdAt =
        response.attendance_created_at !== null
          ? moment(response.attendance_created_at).format('DD/MM/YYYY HH:mm:ss')
          : '';

      attendance.push({
        id: response.attendance_id,
        protocol: response.protocol_protocol,
        name: response.attendance_client_name,
        cpf,
        phone,
        email:
          response.attendance_client_email !== null
            ? response.attendance_client_email
            : '',
        date: createdAt,
        typeId: response.attendance_attendance_type_id,
        typeName: validatorTypeAttendance(
          response.attendance_attendance_type_id
        ),
        channelName:
          response.attendance_channel !== null
            ? response.attendance_channel
            : '',
        statusId: response.attendance_status,
        statusAttendance:
          response.attendance_status === '2' ||
          response.attendance_status === '99'
            ? 'FECHADO'
            : 'ABERTO',
        comment: response.attendance_comment,
      });
    });

    return {
      status: true,
      rows: attendance,
      totalRecord: response.data.paginate.total,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Get List Attendance
 */
export async function getListAttendanceByPhone(type, value) {
  value = type === 'phone' ? `55${value}` : value;

  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/attendance/search/${type}/${value}`,
      { headers: HEADERS }
    );
    const attendance = [];

    if (response.data.length === 0) {
      return { status: true, rows: [] };
    }

    response.data.forEach((user) => {
      user.attendance.forEach((response) => {
        const cpf = user.cpf
          ? user.cpf.toString().length < 11
            ? user.cpf.toString().padStart(11, 0)
            : user.cpf.toString()
          : '';

        const phone = user.phone
          ? user.phone.toString().length > 10
            ? user.phone.toString().substr(2)
            : user.phone.toString()
          : '';

        const createdAt =
          response.attendance_created_at !== null
            ? moment(response.created_at).format('DD/MM/YYYY HH:mm:ss')
            : '';

        attendance.push({
          id: response.id,
          protocol: response.protocol.protocol,
          name: user.name,
          cpf,
          phone,
          email: user.email !== null ? user.email : '',
          date: createdAt,
          typeId: response.attendance_type_id,
          typeName: validatorTypeAttendance(response.attendance_type_id),
          channelName: response.channel,
          statusId: response.status,
          statusAttendance:
            response.status === '2' || response.status === '99'
              ? 'FECHADO'
              : 'ABERTO',
          comment: response.comment,
        });
      });
    });

    return { status: true, rows: attendance };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Get List Attendance
 */
export async function getListAttendanceById(id) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/attendance/${id}`,
      { headers: HEADERS }
    );

    const cpf = response.data[0].client.cpf
      ? response.data[0].client.cpf.toString().length < 11
        ? response.data[0].client.cpf.toString().padStart(11, 0)
        : response.data[0].client.cpf.toString()
      : '';

    const phone = response.data[0].client.phone
      ? response.data[0].client.phone.toString().length > 10
        ? response.data[0].client.phone.toString().substr(2)
        : response.data[0].client.phone.toString()
      : '';

    const createdAt =
      response.data[0].created_at !== null
        ? moment(response.data[0].created_at).format('DD/MM/YYYY HH:mm:ss')
        : '';

    const attendance = [
      {
        id: response.data[0].id,
        protocol: response.data[0].protocol.protocol,
        name: response.data[0].client.name,
        cpf,
        phone,
        email:
          response.data[0].client.email !== null
            ? response.data[0].client.email
            : '',
        date: createdAt,
        typeId: response.data[0].attendance_type_id,
        channelName: response.data[0].channel,
        statusId: response.data[0].status,
        statusAttendance:
          response.data[0].status === '2' || response.data[0].status === '99'
            ? 'FECHADO'
            : 'ABERTO',
        comment: response.data[0].comment,
      },
    ];

    return { status: true, rows: attendance };
  } catch (error) {
    if (error.response) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    if (error.request) {
      return {
        status: false,
        message: `Ops, ocorreu um erro. Tente novamente!`,
      };
    }

    return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
  }
}

/**
 * Function Insert Attendance
 */
export async function insertAttendance(body) {
  try {
    await axios.post(
      `${URL_BACKEND_OPERATION}/callcenter/attendance/open`,
      body,
      { headers: HEADERS }
    );
    return { status: true, message: 'Atendimento efetuado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Update Attendance
 */
export async function updateAttendance(body) {
  try {
    await axios.post(
      `${URL_BACKEND_OPERATION}/callcenter/attendance/answer`,
      body,
      { headers: HEADERS }
    );
    return { status: true, message: 'Atendimento alterado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Delete Attendance
 */
export async function deleteAttendance(id) {
  try {
    await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/attendance/${id}`, {
      headers: HEADERS,
    });
    return { status: true, message: 'Atendimento removido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Get Type Attendance
 */
export async function getTypeAttendance() {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/attendance-type`,
      { headers: HEADERS }
    );

    return { status: true, data: response.data.data };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

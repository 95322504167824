/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ReactDatatable from '@ashvin27/react-datatable';
import ReactTooltip from 'react-tooltip';
import { validatorPermissions } from '../../utils';
import { URL_MAPFRE_CLIENT_INTERFACE } from '../../constants';
import { CONFIG, BUTTONS_NAME } from './settings';

const Datatables = ({
  columns,
  rows,
  loading,
  totalRecord,
  permissions,
  dynamic,
  columnsActionStatus,
  btnAddDefault,
  btnEditDefault,
  btnHistory,
  btnHistoryPayment,
  btnCertificate,
  btnAddAttendance,
  btnImportAttendance,
  btnDeleteDefault,
  btnEditAttendance,
  btnSubscription,
  btnUnSubscription,
  insertRecord,
  editRecord,
  deleteRecord,
  onChange,
  onClickButtonHistoryOrSale,
  onClickButtonUnSubscription,
  onClickButtonHistoryPayment,
  onClickButtonUploadFile,
}) => {
  const [columnsData, setColumnsData] = useState([]);
  /**
   * Button Add Default
   */
  const buttonAdd = () =>
    validatorPermissions(permissions).has('create') &&
    btnAddDefault && (
      <>
        <button
          data-tip={BUTTONS_NAME.addDefault}
          type="button"
          className="btn btn-primary"
          onClick={() => insertRecord(true)}
          style={{ marginBottom: 20 }}
        >
          <i className="fa fa-plus" /> {BUTTONS_NAME.addDefault}
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Edit Default
   */
  const buttonEdit = (record) =>
    validatorPermissions(permissions).has('edit') &&
    btnEditDefault && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.editDefault}
          className="btn btn-primary btn-sm"
          onClick={() => editRecord(record)}
          style={{ marginRight: '5px' }}
        >
          <i className="fa fa-edit" />
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Delete Default
   */
  const buttonDelete = (record) =>
    validatorPermissions(permissions).has('delete') &&
    btnDeleteDefault && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.deleteDefault}
          className="btn btn-danger btn-sm"
          onClick={() => deleteRecord(record)}
        >
          <i className="fa fa-trash" />
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Add Attendance
   */
  const buttonAddAttendance = () =>
    validatorPermissions(permissions).has('create') &&
    btnAddAttendance && (
      <>
        <a
          data-tip={BUTTONS_NAME.addAttendance}
          className="btn btn-primary"
          href="/attendance?a=true"
          style={{ marginBottom: 20 }}
        >
          <i className="fa fa-plus" /> {BUTTONS_NAME.addAttendance}
        </a>
        <ReactTooltip />
      </>
    );

  /**
   * Button Edit Attendance
   */
  const buttonEditAttendance = (record) =>
    validatorPermissions(permissions).has('edit') &&
    btnEditAttendance && (
      <>
        <a
          data-tip={BUTTONS_NAME.editAttendance}
          className="btn btn-primary btn-sm"
          href={`/attendance?e=${record.id}`}
          style={{ marginRight: '5px' }}
        >
          <i className="fa fa-edit" />
        </a>
        <ReactTooltip />
      </>
    );

  /**
   * Button Add Attendance
   */
  const buttonImportAttendance = () =>
    validatorPermissions(permissions).has('create') &&
    btnImportAttendance && (
      <>
        <button
          data-tip={BUTTONS_NAME.importAttendance}
          className="btn btn-w-m btn-warning"
          type="button"
          style={{ marginBottom: 20, marginLeft: 10 }}
          onClick={(e) => onClickButtonUploadFile(e)}
        >
          <i className="fa fa-file" /> {BUTTONS_NAME.importAttendance}
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Subscription
   * @param {obj} record
   */
  const buttonSubscription = (record) =>
    validatorPermissions(permissions).has('create') &&
    btnSubscription && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.subscription}
          style={{ marginRight: 10 }}
          className="btn btn-danger btn-sm"
          onClick={(e) => onClickButtonHistoryOrSale(e, record)}
        >
          <i className="fa fa-plus" /> {BUTTONS_NAME.subscription}
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button History
   * @param {obj} record
   */
  const buttonHistory = (record) =>
    validatorPermissions(permissions).has('list') &&
    btnHistory && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.history}
          className="btn btn-sm btn-primary"
          onClick={(e) => onClickButtonHistoryOrSale(e, record)}
        >
          <i className="fa fa-search" /> {BUTTONS_NAME.history}
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Certificate
   * @param {obj} record
   */
  const buttonCertificate = (record) =>
    validatorPermissions(permissions).has('list') &&
    btnCertificate && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.certificate}
          className="btn btn-warning btn-sm"
          style={{ marginLeft: 5 }}
          onClick={() =>
            window.open(
              `${URL_MAPFRE_CLIENT_INTERFACE}/certificate/${record.extref}`,
              '_blank'
            )
          }
        >
          <i className="fa fa-paste" />
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button History Payment
   * @param {obj} record
   */
  const buttonHistoryPayment = (record) =>
    validatorPermissions(permissions).has('list') &&
    btnHistoryPayment && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.historyPayment}
          className="btn btn-info btn-sm"
          style={{ marginLeft: 5 }}
          onClick={(e) => onClickButtonHistoryPayment(e, record)}
        >
          <i className="fa fa-dollar" />
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Button Unsubscription
   * @param {obj} record
   */
  const buttonUnsubscription = (record) =>
    validatorPermissions(permissions).has('delete') &&
    btnUnSubscription &&
    record.statusSubscription !== 'CANCELADO' && (
      <>
        <button
          type="button"
          data-tip={BUTTONS_NAME.unsubscription}
          className="btn btn-danger btn-sm"
          style={{ marginLeft: 5 }}
          onClick={(e) => onClickButtonUnSubscription(e, record)}
        >
          <i className="fa fa-close" />
        </button>
        <ReactTooltip />
      </>
    );

  /**
   * Function Get Columns
   * @param {object} rowsColumns
   */
  const getColumns = (rowsColumns) => {
    const data = [];
    const buttons = {
      key: 'acoes',
      text: 'AÇÕES',
      width: 100,
      align: 'left',
      sortable: false,
      cell: (record) => (
        <>
          {buttonEdit(record)}
          {buttonDelete(record)}
          {buttonEditAttendance(record)}
          {buttonSubscription(record)}
          {buttonHistory(record)}
          {buttonCertificate(record)}
          {buttonHistoryPayment(record)}
          {buttonUnsubscription(record)}
        </>
      ),
    };

    if (!Array.isArray(rowsColumns)) {
      for (const key in rowsColumns) {
        if (rowsColumns.hasOwnProperty(key)) {
          data.push({
            key,
            text: rowsColumns[key],
            align: 'left',
            sortable: false,
          });
        }
      }
    } else {
      for (const k in rowsColumns) {
        data.push(rowsColumns[k]);
      }
    }

    if (columnsActionStatus !== false && columnsActionStatus !== null) {
      data.push(buttons);
    }

    setColumnsData(data);
  };

  useEffect(() => {
    getColumns(columns);
  }, []);

  return (
    <>
      {buttonAdd()}
      {buttonAddAttendance()}
      {buttonImportAttendance()}

      <ReactDatatable
        config={CONFIG}
        columns={columnsData}
        records={rows}
        loading={loading}
        dynamic={dynamic}
        total_record={totalRecord}
        onChange={onChange}
      />
    </>
  );
};

Datatables.propTypes = {
  columns: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  totalRecord: PropTypes.number.isRequired,
  permissions: PropTypes.array.isRequired,
  dynamic: PropTypes.bool.isRequired,
  columnsActionStatus: PropTypes.bool.isRequired,
  btnAddDefault: PropTypes.bool.isRequired,
  btnEditDefault: PropTypes.bool.isRequired,
  btnHistoryPayment: PropTypes.bool.isRequired,
  btnCertificate: PropTypes.bool.isRequired,
  btnHistory: PropTypes.bool.isRequired,
  btnAddAttendance: PropTypes.bool.isRequired,
  btnImportAttendance: PropTypes.bool.isRequired,
  btnDeleteDefault: PropTypes.bool.isRequired,
  btnEditAttendance: PropTypes.bool.isRequired,
  btnSubscription: PropTypes.bool.isRequired,
  btnUnSubscription: PropTypes.bool.isRequired,
  insertRecord: PropTypes.func.isRequired,
  editRecord: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickButtonHistoryOrSale: PropTypes.func.isRequired,
  onClickButtonUnSubscription: PropTypes.func.isRequired,
  onClickButtonHistoryPayment: PropTypes.func.isRequired,
  onClickButtonUploadFile: PropTypes.func.isRequired,
};

export default Datatables;

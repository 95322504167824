const CONFIG = {
  page: 'attendance',
  system: 'insurance',
  description: 'Seguros :: Atendimentos',
  title: 'Atendimentos',
};

const DISPLAY = {
  page: true,
  list: true,
  form: false,
  search: false,
};

const COLUMNS = {
  id: '#',
  protocol: 'PROTOCOLO',
  name: 'NOME DO CLIENTE',
  date: 'DATA',
  typeName: 'TIPO',
  channelName: 'CANAL',
  statusAttendance: 'STATUS',
};

const FORM_DATA = {
  disabled: false,
  required: false,
  protocol: '',
  date: '',
  name: '',
  cpf: '',
  phone: '',
  email: '',
  channelName: '',
  typeId: '',
  comment: '',
  answer: '',
  statusId: '',
  type: '',
  search: '',
};

export { CONFIG, DISPLAY, COLUMNS, FORM_DATA };

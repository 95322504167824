/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { validateToken } from '../../Token';

/**
 * Function Validator Permissions
 */
export function validatorPermissions(page) {
  const token = validateToken();
  const permission = [];
  const pagePermission = page === 'search' ? 'salle' : page;

  for (const key in token.permission) {
    if (token.permission[key].uri === pagePermission) {
      if (token.permission[key].crud !== null) {
        token.permission[key].crud.forEach((element) => {
          if (element.list === 1) {
            permission.push('list');
          }

          if (element.create === 1) {
            permission.push('create');
          }

          if (element.edit === 1) {
            permission.push('edit');
          }

          if (element.delete === 1) {
            permission.push('delete');
          }
        });
      }
    }
  }
  return new Set(permission);
}

/**
 * Function Validator Permissions URI
 */
export function validatorPermissionsURI(uri) {
  const token = validateToken();
  const userPermissionsInfo = token.permission;
  const url = uri === 'search' ? 'salle' : uri;
  const permission = { status: false };
  for (const key in userPermissionsInfo) {
    if (userPermissionsInfo[key].uri === url) {
      if (userPermissionsInfo[key].crud !== null) {
        userPermissionsInfo[key].crud.forEach((element) => {
          if (element.list === 1) {
            permission.status = true;
          }
        });
      }
    }
  }

  return !!permission.status;
}

/* eslint-disable */
import React, { Component } from 'react';

export default class FlashMessage extends Component {
  /**
   * Function Flash Message
   */
  flashMessage(status, message) {
    switch (status) {
      case true:
        return (
          <div className="alert alert-danger alert-dismissable">{message}</div>
        );

      case false:
        return (
          <div className="alert alert-success alert-dismissable">{message}</div>
        );

      case 'info':
        return (
          <div className="alert alert-info alert-dismissable">{message}</div>
        );

      default:
        break;
    }
  }

  render() {
    return (
      <>
        {this.flashMessage(
          this.props.statusFlashMessage,
          this.props.flashMessage
        )}
      </>
    );
  }
}

/* eslint-disable */
import axios from 'axios';

import { validateToken } from '../../../utils';

import { URL_BACKEND_OPERATION, URL_STORAGE_BACKEND } from '../../../constants';

const HEADERS = {
  'auth-jwt': `Bearer ${validateToken('token')}`,
};

/**
 * Function Get List All Users
 */
export async function getListAllUsers() {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/user/`,
      { headers: HEADERS }
    );
    return { status: true, rows: response.data };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Get List by Users
 */
export async function getListUsers(queryString) {
  try {
    const response = await axios.get(
      `${URL_BACKEND_OPERATION}/callcenter/user/list?${queryString}`,
      { headers: HEADERS }
    );
    const data = [];

    response.data.data.forEach((response) => {
      data.push({
        id: response.user_id,
        name: response.user_name,
        userName: response.user_username,
        groupName: response.group_name,
        subGroupName: response.sub_group_name,
        profileId: response.profile_id,
        profileName: response.profile_name,
        status: response.user_deleted_at !== null ? 'INATIVO' : 'ATIVO',
        deletedUserAt: response.user_deleted_at,
      });
    });

    return {
      status: true,
      rows: data,
      totalRecord: response.data.paginate.total,
    };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Insert Users
 */
export async function insertUsers(body) {
  try {
    await axios.post(`${URL_BACKEND_OPERATION}/callcenter/user/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'Usuário inserido com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Update Users
 */
export async function updateUsers(body) {
  try {
    await axios.put(`${URL_BACKEND_OPERATION}/callcenter/user/`, body, {
      headers: HEADERS,
    });
    return { status: true, message: 'Usuário alterado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Upload File Users
 */
export async function uploadFileUsers(body) {
  try {
    const response = true;
    await axios.post(
      `${URL_STORAGE_BACKEND}/insurance_attendant_import`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return {
      status: response,
      message:
        'Upload do arquivo efetuado com sucesso, aguarde que estamos processando os dados e em breve a lista será atualizada!',
    };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}

/**
 * Function Delete Users
 */
export async function deleteUsers(id) {
  try {
    await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/user/${id}`, {
      headers: HEADERS,
    });
    return { status: true, message: 'Usuário desativado com sucesso!' };
  } catch (error) {
    if (error.request) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    if (error.response) {
      return { status: false, message: `Ops, ocorreu um erro!` };
    }

    return { status: false, message: `Ops, ocorreu um erro!` };
  }
}
